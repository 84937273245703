import { Component, OnInit } from '@angular/core';
import { NotificationResponse } from 'src/app/models/notification';
import { ApiService } from 'src/app/services/api.service';
import { InfoService } from 'src/app/services/info.service';
import { NotificationService } from 'src/app/services/notification.service'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService,
    public infoService: InfoService) { }

  ngOnInit(): void {
  }

  clickedOutside() {
    if (this.notificationService.showNotification === true) {
      setTimeout(() => {
        this.notificationService.showNotification = false
      }, 1);
    }

  }

}
