import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { LoadingService } from './loading.service';
import { catchError, timeout } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GeneralInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loading = this.injector.get(LoadingService)
    return next.handle(req).pipe(timeout(15000), catchError((error) => { // Error...
      // Handle 'timeout over' error
      if (error instanceof TimeoutError) {
        loading.isActive = false
        this.throwSnack('Tempo de execução excedido. Tente novamente.')
        return throwError(error);
      }
      // Return other errors
      loading.isActive = false
      this.throwSnack('Ocorreu um erro. Tente novamente.')
      return throwError(error);
    }))
  }

  throwSnack(message) {
    let _snackBar = this.injector.get(MatSnackBar)
    _snackBar.open(message, 'Ok', {
      duration: 4000,
      panelClass: ['error-snackbar']
    });
  }
}
