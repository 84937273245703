import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InfoService } from 'src/app/services/info.service';
import { BuscaCepService } from 'src/app/services/busca-cep.service';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { SolicitacoesService } from 'src/app/services/solicitacoes.service';
import { TokenService } from 'src/app/services/token.service';
import { LoadingService } from 'src/app/services/loading.service';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import validator from "validar-telefone";


interface buscaCep {
  cep: string;
  logradouro: string,
  complemento: string,
  bairro: string,
  localidade: string;
  uf: string
}
interface tokenInterface {
  access_token: string;
  expires_in: number;
  token_type: string;
}

@Component({
  selector: 'app-nova-solicitacao-certidao',
  templateUrl: './nova-solicitacao-certidao.component.html',
  styleUrls: ['./nova-solicitacao-certidao.component.scss']
})
export class NovaSolicitacaoCertidaoComponent implements OnInit {

  public error = {
    cpfCnpj: {
      message: ''
    },
    phone: {
      message: ''
    }
  }

  profileForm = this.fb.group({
    origem_solicitacao: (''),
    dados1: this.fb.group({
      nome_partes: (''),
      data_ato: (''),
      tipo_ato: (''),
      livro_ato: (''),
      folha_ato: (''),
    }
    ), dados2: this.fb.group({
      nome_partes: (''),
      data_ato: (''),
      tipo_ato: (''),
      livro_ato: (''),
      folha_ato: (''),
    }
    ), dados3: this.fb.group({
      nome_partes: (''),
      data_ato: (''),
      tipo_ato: (''),
      livro_ato: (''),
      folha_ato: (''),
    }
    ), dados4: this.fb.group({
      nome_partes: (''),
      data_ato: (''),
      tipo_ato: (''),
      livro_ato: (''),
      folha_ato: (''),
    }
    ), dados5: this.fb.group({
      nome_partes: (''),
      data_ato: (''),
      tipo_ato: (''),
      livro_ato: (''),
      folha_ato: (''),
    }
    ),
    entrega: [1],
    cep: [''],
    logradouro: [''],
    numero: [''],
    complemento: [''],
    bairro: [''],
    cidade: [''],
    uf: [''],
    nome: [''],
    cpf_cnpj: [''],
    email: ['', ([Validators.required, Validators.email])],
    telefone: [''],
    mensagem: [''],
  })

  @Output() closeModal = new EventEmitter();

  constructor(private fb: FormBuilder,
    public infoService: InfoService,
    public buscaCepService: BuscaCepService,
    private dialog: MatDialog,
    public solicitacoesService: SolicitacoesService,
    private apiService: ApiService,
    private tokenService: TokenService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.infoService.base64 = []
    let entrega = this.profileForm.get('entrega').value
    this.infoService.forma_entrega = entrega
    this.tokenService.getToken().subscribe((result: tokenInterface) => {
      this.infoService.access_token = result.access_token
      this.apiService.setHeader(result.access_token)
      this.loadingService.isActive = false
    })
  }

  phoneNumberIsReal(data) {
    if (data.length >= 10) {
      if (!validator(data)) {
        this.error.phone.message = 'O telefone digitado não é válido.'
      } else {
        this.error.phone.message = ''
      }
    }  else {
      this.error.phone.message = 'Este campo é obrigatório.'
    }
  }

  cpfCnpjIsReal(data) {
    if (data.length > 11) {
      if (!cnpj.isValid(data)) {
        this.error.cpfCnpj.message = 'O CNPJ digitado não é válido.'
      } else {
        this.error.cpfCnpj.message = ''
      }
    } else if (data.length == 11) {
      if (!cpf.isValid(data)) {
        this.error.cpfCnpj.message = 'O CPF digitado não é válido.'
      }else{
        this.error.cpfCnpj.message = ''
      }
    }
    else {
      this.error.cpfCnpj.message = 'Este campo é obrigatório.'
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  selectSolicitationType(e){
    this.profileForm.get('origem_solicitacao').setValue(e.value)
  }

  addFields() {
    if (this.buscaCepService.qtdSolicitacoes < 6) {
      this.buscaCepService.qtdSolicitacoes += 1
      this.buscaCepService.valorServico = 70.80 * this.buscaCepService.qtdSolicitacoes
    }
  }

  removeField() {
    if (this.buscaCepService.qtdSolicitacoes > 0) {
      this.buscaCepService.qtdSolicitacoes -= 1
      this.buscaCepService.valorServico = 70.80 * this.buscaCepService.qtdSolicitacoes
    }
  }


  getCep(params) {
    if (this.profileForm.get('cep').value.length === 8) {
      this.buscaCepService.calcularFrete(this.profileForm.get('cep').value)
      this.apiService.getCepApi(this.buscaCepService.url + params + '/json').subscribe((res: buscaCep) => {
        this.profileForm.get('logradouro').setValue(res.logradouro)
        this.profileForm.get('bairro').setValue(res.bairro)
        this.profileForm.get('cidade').setValue(res.localidade)
        this.profileForm.get('uf').setValue(res.uf)
      })
    }
  }


  handleFileChange(event) {
    const target = event.target
    const { files } = target /* ====  const files = target.files */
    for (let element of files) {
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = event => {
          const dataUri = event.target.result as string;
          const base64 = dataUri.replace(/^data:.+;base64,/, '')
          this.infoService.base64.push({
            nome_arquivo: element.name.toLowerCase(),
            base64 /* == base64: base64 */
          })
        }
        reader.readAsDataURL(element)
      }
    }
  }

  submit() {
    this.openConfirm()
  }

  removeSelectedFile(file) {
    const index = this.infoService.base64.indexOf(file)
    this.infoService.base64.splice(index, 1);
  }


  openConfirm() {
    this.infoService.summaryCheck = this.profileForm.value
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '600px'
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dialog.closeAll()
    });
  }

  onClick(info) {
    if (info === 'Entregar no endereço') {
      this.profileForm.controls['cep'].setValidators([Validators.required])
      this.profileForm.controls['logradouro'].setValidators([Validators.required])
      this.profileForm.controls['numero'].setValidators([Validators.required])
      this.profileForm.controls['bairro'].setValidators([Validators.required])
      this.profileForm.controls['cidade'].setValidators([Validators.required])
      this.profileForm.controls['uf'].setValidators([Validators.required])
      this.profileForm.controls['entrega'].setValue(2)
      this.infoService.forma_entrega = 2
    } else {
      this.profileForm.get('cep').clearValidators();
      this.profileForm.get('logradouro').clearValidators();
      this.profileForm.get('numero').clearValidators();
      this.profileForm.get('bairro').clearValidators();
      this.profileForm.get('cidade').clearValidators();
      this.profileForm.get('uf').clearValidators();
      this.profileForm.get('cep').updateValueAndValidity();
      // this.buscaCepService.frete = 0
      this.profileForm.get('logradouro').updateValueAndValidity();
      this.profileForm.get('numero').updateValueAndValidity();
      this.profileForm.get('bairro').updateValueAndValidity();
      this.profileForm.get('cidade').updateValueAndValidity();
      this.profileForm.get('uf').updateValueAndValidity();
      this.profileForm.controls['entrega'].setValue(1)
      this.infoService.forma_entrega = 1
    }
  }

}
