// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://eek8v9ks7k.execute-api.us-east-1.amazonaws.com/Prod/',
  urlLogin: 'https://eek8v9ks7k.execute-api.us-east-1.amazonaws.com/Prod/',
  urlNotifications: 'https://b7dna4acuf.execute-api.us-east-1.amazonaws.com/v1/',
  urlEscritura: 'https://b7dna4acuf.execute-api.us-east-1.amazonaws.com/v1/'

  /* 
  url: 'https://f2rkl509o9.execute-api.us-west-2.amazonaws.com/Dev/',
  urlLogin: 'https://f2rkl509o9.execute-api.us-west-2.amazonaws.com/Dev' */
};
