<main>
  <section>
    <div class="content-container">
      <div class="first-line">

        <h3>Ordem de Pagamento - {{ ordem.codigo }}</h3>
        <!--  <span (click)="openEdit()" class="material-icons">
          edit
        </span> -->
      </div>

      <form [formGroup]="ordemForm" (ngSubmit)="atualizarOrdem()">
        <div class="details-container mat-elevation-z8">
          <div class="details">
            <fieldset disabled>
              <mat-form-field>
                <mat-label>Número da Solicitação</mat-label>
                <br>
                <input matInput type="text" value="{{ ordem.numero_nota_ato }}" formControlName="numero_nota_ato">
              </mat-form-field>
            </fieldset>
            <fieldset disabled>
              <mat-form-field>
                <mat-label>Data de Lavratura do Ato</mat-label>
                <br>
                <input matInput type="text" value="{{ ordem.data_lavratura_display }}"
                  formControlName="data_lavratura_ato">
              </mat-form-field>
            </fieldset>
            <fieldset disabled>
              <mat-form-field>

                <mat-label>Livro do Ato</mat-label>
                <br>
                <input matInput type="text" value="{{ ordem.livro_ato }}" formControlName="livro_ato">

              </mat-form-field>
            </fieldset>

            <fieldset disabled>
              <mat-form-field>

                <mat-label>Folha do Ato</mat-label>
                <br>
                <input matInput type="text" value="{{ ordem.folha_ato }}" formControlName="folha_ato">

              </mat-form-field>
            </fieldset>
            <fieldset [disabled]="!infoService.enableInputs">
              <mat-form-field>

                <mat-label>Valor Total (R$)</mat-label>
                <br>
                <input matInput type="text" value="{{ ordem.valor_total | currency: 'BRL':true }}"
                  formControlName="valor_total">

              </mat-form-field>
            </fieldset>
            <div class="detail">
              <!--  <div>
                <p>Status da Ordem</p>
                <br>
                {{ordem.descricao_status}}
              </div> -->
              <div *ngIf="infoService.enableInputs">
                <button class="btn cancel-btn" type="button" (click)="closeEdit()" mat-raised-button color="primary">
                  Cancelar
                </button>
                <button class="btn" mat-raised-button color="primary">
                  Atualizar
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>

      <h3 style="margin-top: 20px !important;">Pagadores - Total Declarado {{sumValues() | currency:'BRL':true}}</h3>

      <div class="table-container mat-elevation-z8">
        <mat-table [dataSource]="infoService.pagadores" class="mat-elevation-z8">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.pagador.nome }}</td>
          </ng-container>

          <ng-container matColumnDef="cpf">
            <th mat-header-cell *matHeaderCellDef> CPF/CNPJ</th>
            <td mat-cell *matCellDef="let element">
              {{element.pagador.cpf | mask: element.pagador.cpf.length <= 11 ? '000.000.000-09' : '00.000.000/0000-00' }}
            </td>
            <!-- <td mat-cell *matCellDef="let element"> {{element.pagador.cpf | mask: isCPF(element.pagador.cpf) }}
            </td> -->
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.pagador.email }} </td>
          </ng-container>

          <ng-container matColumnDef="telefone">
            <th mat-header-cell *matHeaderCellDef> Telefone </th>
            <td mat-cell *matCellDef="let element"> {{ element.pagador.telefone }} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{ element.descricao_status }} </td>
          </ng-container>
          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Valor</th>
            <td mat-cell *matCellDef="let element"
              style="display: flex; align-items: center; justify-content: space-between; text-align: left;">
              <div>
                {{element.valor | currency: 'BRL':true}}
              </div>
              <div class="action">
                <button (click)="openConfirm(element)" *ngIf="element.codigo_status !== 3 && element.descricao_status !== 'Pagamento Aprovado'"
                  matTooltip="Finalizar pagamento" mat-mini-fab color="primary"
                  aria-label="Example icon button with a delete icon">
                  <mat-icon>done</mat-icon>
                </button>
                <button
                  *ngIf="element.descricao_status === 'Pagamento Aprovado' || element.descricao_status === 'Pagamento Solicitado'"
                  [disabled]="element.descricao_status === 'Pagamento Solicitado'"
                  matTooltip="Estornar valor total do pagamento" mat-mini-fab
                  color="primary" (click)="pagadorService.refund(element.pagador.cpf)"
                  aria-label="Example icon button with a delete icon">
                  <mat-icon>refresh</mat-icon>
                </button>
                <!--                 <button *ngIf="element.descricao_status != 'Pagamento Aprovado' && element.descricao_status != 'Pagamento Solicitado'" matTooltip="Gerar link de pagamento"
 -->
                <!--  <p>teste</p> -->
                <button *ngIf="element.descricao_status != 'Pagamento Aprovado' &&
                 element.descricao_status != 'Pagamento Solicitado'" matTooltip="Gerar link de pagamento"
                  matTooltip="Gerar link de pagamento"
                  (click)="ordemService.gerarPedido(element.pagador, element.valor)"
                  mat-mini-fab color="primary" [disabled]="element.descricao_status != 'Em aberto' || infoService.clicked"
                  aria-label="Example icon button with whatsapp link">
                  <mat-icon>email</mat-icon>
                </button>
                <button matTooltip="Editar informações do pagador" mat-mini-fab
                  color="primary" [disabled]="element.codigo_status === 3 || element.descricao_status === 'Pagamento Aprovado' ||
                  element.descricao_status === 'Pagamento Solicitado' || element.descricao_status != 'Em aberto' || infoService.clicked" (click)="alterarPagador(element)"
                  aria-label="Example icon button with a delete icon">
                  <mat-icon>edit</mat-icon>
                </button>

                <button [disabled]="element.codigo_status === 3 || element.descricao_status === 'Pagamento Aprovado' ||
                element.descricao_status === 'Pagamento Solicitado'" matTooltip="Excluir pagador" mat-mini-fab
                  color="primary" (click)="openDialog(element.pagador)"
                  aria-label="Example icon button with a delete icon">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>

      <p *ngIf="ordemService?.emailEnviado" style="margin-top: 10px; text-align: center;">
        <ngb-alert>
          Email enviado com sucesso ao destinatário
        </ngb-alert>
      </p>
      <p *ngIf="ordemService?.error" style="margin-top: 10px; text-align: center;">
        <ngb-alert type="danger">
          Ocorreu um erro ao processar as informações. Tente novamente mais tarde.
        </ngb-alert>
      </p>
      <button style="margin-top: 20px;" *ngIf="!novoPagador" (click)="openNovoPagador()" type="button"
        class="novo-pagador btn" mat-raised-button color="primary">
        Novo Pagador
      </button>

    </div>
  </section>
</main>