import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

export interface DialogData {
  nome: string;
  cpf: string;
  solicitacao: string;
}

@Component({
  selector: 'inova-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private apiService: ApiService) { }


  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(cpf): void {
    this.apiService.postCancelarPedido(`cancelar?chave=${this.data.solicitacao}`, {}).then(res => {
      this.dialogRef.close(cpf);
    })
  }

}
