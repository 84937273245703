import { Component, OnInit, Inject } from '@angular/core';
import { OrdemPagadorInterface } from '../models/OrdemPagador';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdemService } from '../services/ordem.service';
import { PagadorService } from '../services/pagador.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdemInterface } from '../models/Ordem';
import { conformToMask } from 'angular2-text-mask';
import { isValidCpf } from '../utils/Validators';
import { InfoService } from '../services/info.service';
import { toBRDate } from '../utils/DateUtils';
import { DialogData } from '../ordem-detalhe/confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdensPagamentoComponent } from '../ordens-pagamento/ordens-pagamento.component';
import { LoadingService } from '../services/loading.service';
import { cnpj, cpf } from 'cpf-cnpj-validator';


@Component({
  selector: 'app-novo-pagador',
  templateUrl: './novo-pagador.component.html',
  styleUrls: ['./novo-pagador.component.scss']
})
export class NovoPagadorComponent implements OnInit {

  public error = {
    message: ''
  }

  codigo: number = 0

  ordem: OrdemInterface = this.infoService.ordemPagador
  pagadorForm: FormGroup = null
  pagadores: OrdemPagadorInterface[] = []
  total_declarado = '';
  total_offset = '';
  novoPagador: boolean = false
  disableFields: boolean = true
  disableInputs: boolean = true
  cpfsearched: string = '';
  cpf_mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]
  finishedLoading: number = 0;
  editingOrdem: OrdemPagadorInterface = {}


  isCPF(cpf_cnpj): string {
    return cpf_cnpj && cpf_cnpj.length < 12 ? '000.000.000-009' : '00.000.000/0000-00';
  }

  get cpf_cnpj() {
    return this.pagadorForm.get('cpf').value
  }

  constructor(
    public dialogRef: MatDialogRef<OrdensPagamentoComponent>,
    private route: ActivatedRoute,
    private ordemService: OrdemService,
    private pagadorService: PagadorService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public infoService: InfoService,
    private loadingService: LoadingService) { }


  ngOnInit(): void {
    if (this.infoService.isNewPayer) {
      this.infoService.editOrderButtonIsClicked = false
      this.codigo = this.route.snapshot.params.codigo;
      this.pagadorForm = this.formBuilder.group({
        cpf: [{ value: '', disabled: false }, [Validators.required]],
        nome: [{ value: '', disabled: false }, Validators.required],
        email: [{ value: '', disabled: false }, Validators.email],
        telefone: [{ value: '', disabled: false }, Validators.required],
        valor: ['', Validators.required],
      })

    } else {
      this.infoService.isNewPayer = false
      this.codigo = this.route.snapshot.params.codigo;
      let cpf = this.infoService.selectedPayer.pagador.cpf
      this.pagadorForm = this.formBuilder.group({
        cpf: [cpf, [Validators.required]],
        nome: [this.infoService.selectedPayer.pagador.nome, Validators.required],
        email: [this.infoService.selectedPayer.pagador.email, Validators.email],
        telefone: [this.infoService.selectedPayer.pagador.telefone, Validators.required],
        valor: [this.infoService.selectedPayer.valor, Validators.required],
      });
      this.pagadorForm.controls['cpf'].disable()
    }
  }

  
  cpfCnpjIsReal(data) {
    if (data.length > 11) {
      if (!cnpj.isValid(data)) {
        this.error.message = 'O CNPJ digitado não é válido.'
      } else {
        this.error.message = ''
      }
    } else if (data.length == 11) {
      if (!cpf.isValid(data)) {
        this.error.message = 'O CPF digitado não é válido.'
      }else{
        this.error.message = ''
      }
    }
    else {
      this.error.message = 'Este campo é obrigatório.'
    }
  }

  phone_mask = function (rawValue) {
    rawValue += ''
    rawValue = rawValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\ /g, '').replace(/\-/g, '').replace(/\_/g, '')
    if (rawValue.length <= 10) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d|''/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }

  associate() {
    this.pagadorForm.controls['cpf'].enable()
    if (!this.infoService.isNewPayer) {
      this.loadingService.isActive = true;
      this.pagadorService.dessasociarPagador(this.ordem.codigo, this.pagadorForm.value.cpf)
        .then((result) => {
          let ordemPagadorInterface: OrdemPagadorInterface = {}
          ordemPagadorInterface.ordem = this.ordem
          ordemPagadorInterface.pagador = this.pagadorForm.value
          ordemPagadorInterface.valor = this.pagadorForm.get('valor').value
          ordemPagadorInterface.codigo_pedido = '0'
          this.pagadorService.associatePagadorOrdem(this.ordem.codigo, ordemPagadorInterface).then((result) => {
            this.pagadores.push(ordemPagadorInterface);
            this.loadTotais()
            this.novoPagador = false;
            this.pagadorForm.reset();
            this.closeModal()
            this.infoService.loadPagadores()
            this.loadingService.isActive = false;
          })
            .catch((err) => {
              this.loadingService.isActive = false;
              this._snackBar.open(
                'Ocorreu um erro, por favor tente novamente mais tarde',
                'Ok',
                {
                  duration: 4000,
                  panelClass: ['error-snackbar'],
                }
              );
            });
        })
        .catch((err) => {
          this.loadingService.isActive = false;
          this._snackBar.open(
            'Ocorreu um erro, por favor tente novamente mais tarde',
            'Ok',
            {
              duration: 4000,
              panelClass: ['error-snackbar'],
            }
          );
        });
    } else {

      this.loadingService.isActive = true;
      let ordemPagadorInterface: OrdemPagadorInterface = {};
      ordemPagadorInterface.ordem = this.ordem;
      ordemPagadorInterface.pagador = this.pagadorForm.value;
      ordemPagadorInterface.valor = this.pagadorForm.get('valor').value;
      ordemPagadorInterface.codigo_pedido = '0';

      this.pagadorService
        .associatePagadorOrdem(this.ordem.codigo, ordemPagadorInterface)
        .then((result) => {
          
          this.pagadores.push(ordemPagadorInterface);
          this.loadTotais()
          this.novoPagador = false;
          this.pagadorForm.reset();
          this.closeModal()
          this.infoService.loadPagadores()
          this.loadingService.isActive = false;
        })
        .catch((err) => {
          
          this.loadingService.isActive = false;
          this._snackBar.open(
            'Ocorreu um erro, por favor tente novamente mais tarde',
            'Ok',
            {
              duration: 4000,
              panelClass: ['error-snackbar'],
            }
          );
        });
    }
  }

  cancelAlterar() {
    this.pagadores.push(this.editingOrdem)
    this.novoPagador = false
  }

  closeModal(): void {
    this.dialogRef.close(NovoPagadorComponent);
  }
  loadOrdem() {
    this.loadingService.isActive = true;
    this.ordemService
      .getOrdem(this.codigo)
      .then((ordem: OrdemInterface) => {
        ordem.data_lavratura_display = toBRDate(ordem.data_lavratura);
        this.ordem = ordem;
        this.ordem.valor_total = conformToMask(
          ordem.valor_total,
          this.money_mask,
          { guide: false }
        ).conformedValue
        this.finishedLoading++
        if (this.finishedLoading >= 2) {
          this.loadTotais()
        }
        this.loadingService.isActive = false;
      })
      .catch((err) => {
        this.loadingService.isActive = false;
      });
  }

  loadTotais() {
    let valor_declarado = 0
    this.pagadores.forEach(p => {
      valor_declarado += this.parseMoney(p.valor)
    })
    let count = (valor_declarado - this.parseMoney(this.ordem.valor_total))

    let offsetNegative = count < 0 ? '-' : '+';
    let offset = count + ''
    if (offset.indexOf('.') === -1) {
      offset += '00'
    } else {
      offset = offset.split('.')[0] + offset.split('.')[1].padEnd(2, '0')
    }

    let declared = valor_declarado + ''
    if (declared.indexOf('.') === -1) {
      declared += '00'
    } else {
      declared = declared.split('.')[0] + declared.split('.')[1].padEnd(2, '0')
    }

    this.total_declarado = 'R$ ' + conformToMask(
      declared,
      this.money_mask,
      { guide: false }
    ).conformedValue
    this.total_offset = offsetNegative + 'R$ ' + conformToMask(
      offset,
      this.money_mask,
      { guide: false }
    ).conformedValue
  }

  parseMoney(txt) {
    txt = txt.toString()
    txt = txt.replace(/\./g, '').replace(/\,/, '.')
    return parseFloat(txt)
  }

  money_mask = function (rawValue) {
    rawValue += '';
    rawValue = rawValue.replace(/\./g, '').replace(/\,/g, '')
    let isNegative = rawValue.indexOf("-") !== -1 ? true : false
    rawValue = rawValue.replace(/\-/g, '');
    let mask = [];
    let ct = 0;
    for (var x = rawValue.length; x > 0; x--) {
      if (x == rawValue.length) {
        mask.push(/[0-9]/)
      } else if (x == (rawValue.length - 1)) {
        mask.push(/[0-9]/)
      } else {
        if (x == (rawValue.length - 2)) {
          mask.push(',')
        }
        if (ct === 3) {
          mask.push('.')
          ct = 0;
        }
        ct++
        mask.push(/[0-9]/)

      }
    }
    return mask.reverse()
  }

 /*  cpfChange(value) {
    let rawValue = value;
    if (this.cpfsearched !== rawValue) {
      this.cpfsearched = rawValue;
      value = value.replace(/\./g, '').replace(/\-/g, '').replace(/\_/g, '')
      if (value.length == 11 && this.pagadorForm.get('cpf').valid || value.length == 15 && this.pagadorForm.get('cpf').valid) {
        this.loadingService.isActive = true
        this.pagadorService.getPagadorPorCPF(rawValue)
          .then((pagador: any) => {
            this.pagadorForm.get('nome').setValue(pagador.nome)
            this.pagadorForm.get('nome').enable()
            this.pagadorForm.get('telefone').setValue(pagador.telefone)
            this.pagadorForm.get('telefone').enable()
            this.pagadorForm.get('email').setValue(pagador.email)
            this.pagadorForm.get('email').enable()
            this.disableFields = false;
            this.loadingService.isActive = false;
          })
          .catch(err => {
            this.pagadorForm.get('nome').setValue('')
            this.pagadorForm.get('telefone').setValue('')
            this.pagadorForm.get('email').setValue('')
            this.pagadorForm.get('nome').enable()
            this.pagadorForm.get('telefone').enable()
            this.pagadorForm.get('email').enable()
            this.disableFields = false;
            this.loadingService.isActive = false;
          })
      } else {
        this.pagadorForm.get('nome').setValue('')
        this.pagadorForm.get('telefone').setValue('')
        this.pagadorForm.get('email').setValue('')
        this.pagadorForm.get('nome').disable()
        this.pagadorForm.get('telefone').disable()
        this.pagadorForm.get('email').disable()
      }
    }
  }
 */
}



