<inova-navbar *ngIf="loginService.user; else login">
  <div class="content">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</inova-navbar>

<ng-template #login>
  <main>
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
</ng-template>
<inova-loading *ngIf="loading"></inova-loading>
