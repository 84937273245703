import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

// const TOKEN_URL = " https://inova-pgto.auth.us-west-2.amazoncognito.com/oauth2"
const TOKEN_URL = "https://inova-cartorios.auth.us-east-1.amazoncognito.com/oauth2"

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Basic NTZyODNnNHFmcG1lZHMxc3ZqN2tuYm80ODE6MWwzbWw1dWZjNDlsYmllMXZiODRqODZlOHRqNWZldjJob2sxM3E5Zmxmbm5kODBmazFsMg=='
      'Authorization': 'Basic NDUxZGhub2ptdXJrOG1hMmlvcGhtY3Nma3A6cmF1OXNqNXM5MmQ2cHE2bjlicW02YTNjbjg0ZGtibDB2djhiM3FiOW0xaXRvYzQ0azBp'
    }
  ),
};

class IToken {
  access_token: string;
  expires_in: number;
  token_type: string;
}


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public logo: string = ''
  public user

  get codigoCartorio() {
    const user = JSON.parse(localStorage.getItem('logged-user'))
    if(user === null ){
      return null
    }
    this.logo = user.codigo
    return user.codigo || null
  }

  get usuario() {
    const user = JSON.parse(localStorage.getItem('logged-user'))
    if(user === null ){
      return null
    }
    this.user = user
    return user || null
  }

  get token() {
    return this.tkn
  }

  private tkn: IToken = {
    access_token: '',
    expires_in: 0,
    token_type: '',
  };

  constructor(private http: HttpClient, private router: Router) { }

  getToken() {
    // return this.http.post<IToken>(`${TOKEN_URL}/token`, `grant_type=client_credentials&scope=inova-pgto/inova-pgto`, httpOptions);
    return this.http.post<IToken>(`${TOKEN_URL}/token`, `grant_type=client_credentials&scope=inova-cartorios/inova-cartorios`, httpOptions);
  }

  public async validarToken() {
    const tkn = await this.getToken().toPromise()
    this.tkn = tkn;
    return tkn.access_token
  }
}
