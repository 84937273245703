import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'inova-navbar-login',
  templateUrl: './navbar-login.component.html',
  styleUrls: ['./navbar-login.component.scss'],
})
export class NavbarLoginComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
