import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string = ''

  userForm: FormGroup = this.formBuilder.group({
    email: ['', Validators.required],
    senha: ['', Validators.required]
  });

  constructor(
    private _snackBar: MatSnackBar,
    private loginService: LoginService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private notificationService: NotificationService
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
      this.router.navigate(['solicitacoes'])
    });
  }

  ngOnInit(): void {
    this.loadingService.isActive = false
  }

  get formControls() { return this.userForm.controls; }

  login() {
    this.loadingService.isActive = true;
    this.loginService.logar(this.userForm.value)
      .then((data: any) => {
        if (data.codigo === 1) {
          let userData = {
            email: data.user.email,
            nome: data.user.nome,
            codigo: data.cartorio.codigo,
            timestamp: new Date()
          }
          localStorage.setItem('logged-user', JSON.stringify(userData))          
          if (this.returnUrl == this.returnUrl) {
            this.router.navigate(['solicitacoes'])
          }
          this.notificationService.getAllNotification(0, 5)
        } else {
          this.loadingService.isActive = false;
          this.ativarSnackBar('Os valores informados estão incorretos')
        }
      })
      .catch(err => {
        this.loadingService.isActive = false;
        if (err.status == 401) {
          this.ativarSnackBar('Os valores informados estão incorretos')
        } else {
          this.ativarSnackBar('Ocorreu um erro, por favor tente mais tarde')
        }
      })
  }

  ativarSnackBar(mensagem) {
    this._snackBar.open(mensagem, 'Ok', {
      duration: 4000,
      panelClass: ['error-snackbar']
    });
  }
}
