<form (submit)="associate()" [formGroup]="pagadorForm">
    <mat-form-field class="nome">
        <mat-label>Nome Completo</mat-label>
        <input formControlName="nome" matInput placeholder="Ex. Fulano da Silva" value="" />
    </mat-form-field>

    <div class="d-flex">
        <div class="d-flex mr-2 mr-2" style="flex-direction: column; width: 100%;">
            <mat-form-field class="cpf">
                <mat-label>CPF/CNPJ</mat-label>
                <input #cpfIpt matInput [mask]="isCPF(cpf_cnpj)" formControlName="cpf" on-focusout="cpfCnpjIsReal(pagadorForm.get('cpf').value)" value="" />
            </mat-form-field>
            <small style="left: 10px; position: relative; top: -15px; color: red;"> {{error.message}} </small>
        </div>

        <mat-form-field class="email" style="width: 100%;">
            <mat-label>Email</mat-label>
            <input type="email" matInput placeholder="Ex. teste@teste.com" formControlName="email" value="" />
        </mat-form-field>

    </div>

    <div class="line3">
        <mat-form-field class="telefone">
            <mat-label>Telefone</mat-label>
            <input matInput placeholder="Ex. (11) 1234-5236" [textMask]="{mask: phone_mask}" formControlName="telefone"
                value="" />
        </mat-form-field>

        <mat-form-field class="valor">
            <mat-label>Valor (R$)</mat-label>
            <input matInput formControlName="valor" placeholder="Ex. 1200,00" value="" currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" />
        </mat-form-field>
    </div>

    <div class="action">
        <button type="button" class="novo-pagador btn" (click)="closeModal()" mat-raised-button color="primary">
            Cancelar
        </button>
     <!--    <button *ngIf="!infoService.isNewPayer" type="submit"
            [disabled]="infoService.editOrderButtonIsClicked || pagadorForm.invalid" class="novo-pagador btn"
            mat-raised-button color="primary">
            Atualizar
        </button> -->
        <button *ngIf="infoService.isNewPayer" type="submit"
            [disabled]="infoService.editOrderButtonIsClicked || pagadorForm.invalid || error.message != '' " class="novo-pagador btn"
            mat-raised-button color="primary">
            Cadastrar
        </button>

    </div>
</form>