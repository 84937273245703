import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationResponse } from '../models/notification'
import { ApiService } from './api.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  allNotification: NotificationResponse = null
  loading: boolean = false
  showNotification: boolean = false
  currentPage: number = 0
  size: number = 5
  reachedMaxViews: boolean = false
  // TESTE
  
  constructor(public apiService: ApiService,
    public tokenService: TokenService) { }


  getAllNotification(page?, size?) {   
  
    this.loading = true
    this.apiService.getApiNotification(`obternotificacoes?codigoCartorio=${this.tokenService.codigoCartorio}
    &size=${size ? size : this.size}&page=${page ? page : this.currentPage}
    &lida=false`).then((res: NotificationResponse) => {
      this.allNotification = res
      if (this.allNotification.page.totalPages === 1) {
        this.reachedMaxViews = true      
      }
      this.loading = false
    })
  }

  loadMoreNotifications() {
    
      this.loading = true
      this.currentPage += 1
      this.size += 5
      this.getAllNotification(this.currentPage, this.size)
  }

  markRead(condition?) {
    return {
      'font-weight': `${condition ? 'normal' : 'bold'}`
    }
  }

  updateNotification(notification) {
    this.loading = true
    let link = notification._links.notificacaoEntity.href
    let index = link.substr(link.lastIndexOf('/') + 1)
    const data = {
      codigo: Number(index),
      codigoCartorio: notification.codigoCartorio,
      descricao: notification.descricao,
      emailUsuario: notification.emailUsuario,
      lida: true,
      link: notification.link
    }
    this.apiService.putApiNotification(`notificacao-entities/${index}`, data).then(res => {
      this.loading = false
    })

  }

}
