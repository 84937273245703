<form [formGroup]="ordemForm" (ngSubmit)="cadastrarOrdem()">
  <div class="inova-dialog">
    <h2 class="inova-dialog-title" mat-dialog-title>Nova Ordem de Pagamento</h2>
    <div class="inova-dialog-content" mat-dialog-content>
      <div class="line-1">
        <mat-form-field>
          <mat-label>Número da Solicitação</mat-label>
          <input matInput placeholder="Ex. 12345" value="" formControlName="numero_nota_ato" />
        </mat-form-field>

        <mat-form-field class="num-ato">
          <mat-label>Data de Lavratura do Ato</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="data_lavratura_ato" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="line-2">
        <mat-form-field>
          <mat-label>Livro do Ato</mat-label>
          <input matInput placeholder="Ex. 123" value="" formControlName="livro_ato" />
        </mat-form-field>

        <mat-form-field class="fol-ato">
          <mat-label>Folha do Ato</mat-label>
          <input matInput placeholder="Ex. 123" value="" formControlName="folha_ato" />
        </mat-form-field>
      </div>
      <mat-form-field class="value">
        <mat-label>Valor Total (R$)</mat-label>
        <input  matInput placeholder="Ex. 1.200,00" currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" value="" formControlName="valor_total" />
      </mat-form-field>
    </div>
    <!-- <mat-toolbar *ngIf="success_warn" class="inova-dialog-warn" color="primary">
      <h4>Ordem de Pagamento cadastrada com sucesso</h4>
    </mat-toolbar> -->
    <div class="inova-dialog-actions" mat-dialog-actions>
      <button type="button" class="btn" color="primary" mat-raised-button (click)="onNoClick()">
        Cancelar
      </button>
      <button type="submit" class="btn" mat-raised-button color="primary">
        Cadastrar
      </button>
      <button *ngIf="success_warn" color="primary" color="primary" mat-raised-button type="button" (click)="novaOrdem()" mat-raised-button
        color="primary">
        Nova Ordem
      </button>
    </div>
  </div>
</form>