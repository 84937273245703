import { Injectable } from '@angular/core';
import { ISolitacao } from '../models/solicitacoes';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { NovaSolicitacaoCertidaoComponent } from '../pages/solicitacoes/components/nova-solicitacao-certidao/nova-solicitacao-certidao.component';
import { MatDialog } from '@angular/material/dialog';
import { BuscaCepService } from './busca-cep.service';


@Injectable({
  providedIn: 'root'
})
export class SolicitacoesService {
  public solicitacaoSelecionada: ISolitacao = null;
  public codigoRastreio: string = null;
  public atualizarTela = new BehaviorSubject<boolean>(false)
  private resumo = new Subject<Array<any>>();
  public getResumo = [];

  private responseStatus = [];
  public set setResponseStatus(status) {
    this.responseStatus = status
    this.setResumo();
  }

  private totalStatus = [];
  public set setTotalStatus(status) {
    this.totalStatus = status
    this.setResumo();
  }

  constructor(private apiService: ApiService,
    public dialog: MatDialog,
    public buscaCepService: BuscaCepService) {
    this.resumo.subscribe(r => {   
  
      this.getResumo = r;
    })
    this.getStatus()
  }

  private async getStatus() {
    if (this.responseStatus?.length === 0) {
      const status = await this.apiService.getApi('/solicitacoes/status')     
      this.setResponseStatus = status;
    }
  }

  status(id) {   
    return this.responseStatus.find(status => status.codigo === id)
  }

  private setResumo() {
    if (this.responseStatus?.length > 0) {
      const status = this.responseStatus.map(status => {
        const findCount = this.totalStatus.find(x => x.codigo === status.codigo)?.count
        status.count = findCount ? findCount : 0
        return { ...findCount, ...status }
      });
      this.resumo.next(status)
    }
  }

  novaSolicitacao() {
    const dialogRef = this.dialog.open(NovaSolicitacaoCertidaoComponent, {
      width: '800px',
      height: '800px'
    })
  }


}
