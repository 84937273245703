import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { IfStmt } from '@angular/compiler';
import { environment } from 'src/environments/environment';

const API_URL = environment.url

interface Iuser {
  email: string;
  nome: string;
  timestamp: Date;
}
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  get user(): Iuser | null {
    const userlocal: Iuser = JSON.parse(localStorage.getItem('logged-user'));
    if (userlocal && userlocal.email) {
      return userlocal
    } else {
      null
    }
  }

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  async logar(userForm: any) {
    const token = await this.tokenService.getToken().toPromise()
    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token.access_token
        }
      )
    };
    return await this.http.post(`${API_URL}login`, userForm, httpOptions).toPromise()

  }
}
