import { OrdemInterface } from './Ordem';
import { PagadorInterface } from './Pagador';

export class OrdemPagadorInterface {
  ordem?: OrdemInterface;
  pagador?: PagadorInterface;
  codigo_pedido?: string;
  codigo_status?: number;
  descricao_status?: string;
  valor?: number;
  codigo?: string;
}
