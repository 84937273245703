<main>
  <form [formGroup]="userForm" autocomplete="false" (ngSubmit)="login()">
    <div class="flex">
      <div class="img">
        <img src="../../assets/logo-small.jpg" />
      </div>
      <div class="data">
        <h3 class="data__title">Seja bem-vindo</h3>
        <p>Faça o login para acessar sua conta:</p>
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input matInput type="email" matInput required value="" placeholder="email" formControlName="email" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Senha</mat-label>
          <input type="password" matInput required value="" placeholder="senha" formControlName="senha" />
        </mat-form-field>
        <mat-card-actions>
          <button [disabled]="userForm.invalid" class="btn" type="submit" mat-raised-button color="primary">Continuar</button>
        </mat-card-actions>
      </div>
    </div>
  </form>
</main>
