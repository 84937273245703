<div class="content fade-in">
    <mat-card (clickOutside)="clickedOutside()">
        <div *ngFor="let notification of notificationService?.allNotification?._embedded.notificacaoEntities; let i = index"
            class="result">

            <a class="d-flex align-items-center dropdown-item">
                <div (click)="notificationService.updateNotification(notification)"
                    [ngStyle]="notificationService.markRead(notification.lida)">
                    <span [ngStyle]="notificationService.markRead(notification.lida)" class="small text-gray-500">
                        {{notification.descricao}}
                    </span>
                    <p class="tl">{{notification.link | date: 'dd-MM-yyyy'}}</p>
                </div>
            </a>
            <hr>

        </div>
        <div class="spinner">
            <div class="text">
                <a [hidden]="notificationService.reachedMaxViews || notificationService.allNotification.page.totalPages === 0"
                    (click)="notificationService.loadMoreNotifications()"
                    class="pointer text-center dropdown-item small text-gray-500">Ver Mais...
                </a>
                <h5 *ngIf="notificationService?.allNotification?.page.totalElements === 0">Sem novas notificações</h5>
            </div>
            <mat-spinner *ngIf="notificationService.loading && notificationService.showNotification" [diameter]="30">
            </mat-spinner>
        </div>
    </mat-card>
</div>