import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { OrdemInterface } from '../models/Ordem';
import { OrdemPagadorInterface } from '../models/OrdemPagador';
import { conformToMask } from 'angular2-text-mask';
import { PagadorService } from './pagador.service';
import { BuscaCepService } from './busca-cep.service';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';
import { SolicitacoesService } from './solicitacoes.service';
import { TokenService } from './token.service';
import { MultiSolicitationService } from './multi-solicitation.service'
import { de } from 'date-fns/locale';
import { Uploads } from '../models/uploads';

import moment from 'moment';

interface arrayConsulta {
  codigo: number;
  data_criacao: Date;
  codigo_simples: number;
  resumo: [
    {
      descricao: string;
      id: number;
      qtd: number;
    }
  ],
  solicitacoes: [
    {
      data_ato: string;
      codigo: number;
      creation_date: string;
      folha_ato: string;
      forma_entrega: number;
      livro_ato: string;
      mensagem: string;
      nome_partes: string;
      entrega: number;
      solicitante: {
        email: string;
        nome: string;
        cpf_cnpj: string;
        telefone: string;
      },
      status: string;
      tipo_ato: string;
      valor_frete: number;
      valor_solicitacao: number;
    }
  ],
  tipo_retirada: string;

}

interface tokenInterface {
  access_token: string;
  expires_in: number;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})

export class InfoService {

  opcaoEntregaSelecionada: string = 'Retirar no cartório';
  opcoesEntrega: string[] = ['Retirar no cartório', 'Entregar no endereço'];
  forma_entrega
  base64 = []
  summaryCheck: arrayConsulta
  access_token
  clicked = false
  data_ato = null

  loadingValorSolicitacao = true
  loadingStatusPagadores = false
  logoUrl = ''
  showContent = false
  showNotification: boolean = false
  menuIsOpen: boolean = false
  enableInputs: boolean = false
  editOrderButtonIsClicked: boolean = false
  ordemPagador: OrdemInterface = {}
  callBackOrdemDetalhe = 0
  isNewPayer = false
  selectedPayer: OrdemPagadorInterface = new OrdemPagadorInterface()
  loading: boolean = false
  codigo = ''
  pagadores: OrdemPagadorInterface[] = []
  finishedLoading: number = 0
  ordem: OrdemInterface = {}
  total_declarado = '';
  total_offset = '';
  pedidoGerado
  newOffset = ''
  valorTotalSolicitacao = 0
  ordemNew
  qtdSolicitacoes = 1

  /* Arquivos */
  uploads: Uploads = {
    documentosBem: [],
    documentosComprador: [],
    documentosVendedor: []
  }
  /* Arquivos */

  /* Dados da solicitação */
  resultadoPesquisa: any
  showRes = false
  /* Dados da solicitação */

  constructor(private pagadorService: PagadorService,
    public buscaCepService: BuscaCepService,
    public apiService: ApiService,
    private loadingService: LoadingService,
    public solicitacoesServices: SolicitacoesService,
    public tokenService: TokenService,
    public multiSolicitationService: MultiSolicitationService,
    private router: Router) {
  }

  money_mask = function (rawValue) {
    rawValue += '';
    rawValue = rawValue.replace(/\./g, '').replace(/\,/g, '')
    let isNegative = rawValue.indexOf("-") !== -1 ? true : false
    rawValue = rawValue.replace(/\-/g, '');
    let mask = [];
    let ct = 0;
    for (var x = rawValue.length; x > 0; x--) {
      if (x == rawValue.length) {
        mask.push(/[0-9]/)
      } else if (x == (rawValue.length - 1)) {
        mask.push(/[0-9]/)
      } else {
        if (x == (rawValue.length - 2)) {
          mask.push(',')
        }
        if (ct === 3) {
          mask.push('.')
          ct = 0;
        }
        ct++
        mask.push(/[0-9]/)
      }
    }
    return mask.reverse()
  }

  gerarPedido(inputs) {
    let dataWithAddress: Object
    let dataWithoutAddress: Object
    switch (this.buscaCepService.qtdSolicitacoes) {
      case 1:
        dataWithAddress = this.multiSolicitationService.dados1WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados1WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 2:
        dataWithAddress = this.multiSolicitationService.dados2WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados2WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 3:
        dataWithAddress = this.multiSolicitationService.dados3WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados3WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 4:
        dataWithAddress = this.multiSolicitationService.dados4WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados4WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
      case 5:
        dataWithAddress = this.multiSolicitationService.dados5WA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        dataWithoutAddress = this.multiSolicitationService.dados5WOA(inputs, this.forma_entrega, this.buscaCepService.valorServico, this.buscaCepService.frete)
        break;
    }

    this.apiService.setHeader(this.access_token)
    debugger
    if (this.forma_entrega === 2) {
      this.apiService.postFormApi<any>('/solicitacoes', { ...dataWithAddress, origem_solicitacao: inputs.origem_solicitacao }).subscribe(result => {
        this.uploadArquivo(result.solicitacao.codigo)
        this.loadingService.isActive = false
        this.clicked = false
        this.solicitacoesServices.atualizarTela.next(true)
      }, error => {
        this.loadingService.isActive = false
        this.clicked = false
      })
    } else {
      this.apiService.postFormApi<any>('/solicitacoes', { ...dataWithoutAddress, origem_solicitacao: inputs.origem_solicitacao }).subscribe(result => {
        this.uploadArquivo(result.solicitacao.codigo)
        this.loadingService.isActive = false
        this.clicked = false
        this.solicitacoesServices.atualizarTela.next(true)
      }, error => {
        this.loadingService.isActive = false
        this.clicked = false
      })
    }
  }

  uploadArquivo(userCode) {
    this.apiService.postFork('/solicitacoes/' + userCode + '/uploads', this.base64).subscribe(res => {
      this.loadingService.isActive = false
    })
  }

  convertMoneyMask() {
    this.newOffset = this.total_offset.substr(1)
  }

  phone_mask = function (rawValue) {
    rawValue += '';
    rawValue = rawValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\ /g, '').replace(/\-/g, '').replace(/\_/g, '')
    if (rawValue.length <= 10) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d|''/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }

  loadPagadores() {
    this.loading = true;
    this.pagadorService
      .getPagadoresPorOrdem(this.codigo)
      .then((pagadores: OrdemPagadorInterface[]) => {
        this.pagadores = pagadores.map(p => {


          if (p.pagador.telefone.indexOf('(') === -1) {
            p.pagador.telefone = conformToMask(
              p.pagador.telefone,
              this.phone_mask,
              { guide: false }
            ).conformedValue
            return p;
          } else {
            return p;
          }
        });
        this.finishedLoading++
        if (this.finishedLoading >= 2) {
          this.loadTotais()
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  parseMoney(txt) {
    txt = txt.replace(/\./g, '').replace(/\,/, '.')
    return parseFloat(txt)
  }

  loadTotais() {
    setTimeout(() => {
      let valor_declarado = 0
      this.pagadores.forEach(p => {
        valor_declarado += this.parseMoney(p.valor)
      })


      let count = parseFloat((valor_declarado - this.parseMoney(this.ordemPagador.valor_total)).toFixed(2))

      let offsetNegative = count < 0 ? '-' : '+';
      let offset = count + ''
      if (offset.indexOf('.') === -1) {
        offset += '00'
      } else {

        offset = offset.split('.')[0] + offset.split('.')[1].padEnd(2, '0')
      }

      let declared = valor_declarado + ''
      if (declared.indexOf('.') === -1) {
        declared += '00'
      } else {

        declared = declared.split('.')[0] + declared.split('.')[1].padEnd(2, '0')
      }

      this.total_declarado = 'R$ ' + conformToMask(declared, this.money_mask, { guide: false }).conformedValue
      this.total_offset = offsetNegative + 'R$ ' + conformToMask(offset, this.money_mask, { guide: false }).conformedValue
      this.convertMoneyMask()
    }, 1000);
  }

  dateMask(data) {
    if (data) {
      for (let index = 0; index <= data.length - 1; index++) {
        const element = data[index];
        let config = moment(element.data_ato)
        element.data_ato = config.format('DD/MM/YYYY')
      }
    }
  }

  revertDateFormat(data) {
    if (data) {
      for (let index = 0; index <= data.length - 1; index++) {
        const element = data[index];
        if (element.data_ato != 'Invalid date') {
          let free = element.data_ato?.replaceAll('/', '')
          const config = new Date(Number(free.substr(4, 4)), Number(free.substr(2, 2) - 1), Number(free.substr(0, 2))).toISOString()
          element.data_ato = config
        } else {
          delete element.data_ato
        }
      }
    }
  }

  async editarSolicitacao(inputs, novo_frete?) {
    await this.revertDateFormat(inputs.dados)
    this.loadingService.isActive = true
    this.apiService.setHeader((this.tokenService.token.access_token))
    delete inputs.valor_frete
    this.apiService.putApi(`solicitacoes/${inputs.codigo}`, { ...inputs, valor_frete: novo_frete }).then(result => {
      this.loadingService.isActive = false
      this.dateMask(inputs.dados)
      this.solicitacoesServices.atualizarTela.next(true)
    }, error => {
      this.loadingService.isActive = false
      this.dateMask(inputs.dados)
      this.solicitacoesServices.atualizarTela.next(true)
    })
  }

  consultar(codigo: string) {
    this.apiService.getApiEscrituras('escritura/?codigo=' + codigo).subscribe(res => {
      this.resultadoPesquisa = res
      this.showRes = true
      this.loadingService.isActive = false
    }, err => {
      this.loadingService.isActive = false
    })
  }

  upload(fileList: FileList, number: number) {
    if (fileList.length > 0) {
      if (number === 1) {
        this.uploads.documentosBem.push(fileList[0])
      } else if (number === 2) {
        this.uploads.documentosComprador.push(fileList[0])
      } else if (number === 3) {
        this.uploads.documentosVendedor.push(fileList[0])
      }
    }
  }

  gerarSolicitacao(formulario: any) {
    let formData = new FormData
    this.uploads.documentosBem.forEach(element => {
      formData.append('documentosBem', element)
    })
    this.uploads.documentosComprador.forEach(element => {
      formData.append('documentosComprador', element)
    })
    this.uploads.documentosVendedor.forEach(element => {
      formData.append('documentosVendedor', element)
    })
    formData.append('nomeContato', formulario.nomeContato)
    formData.append('telefoneContato', formulario.telefoneContato)
    formData.append('emailContato', formulario.emailContato)
    formData.append('valorBem', formulario.valorBem)
    formData.append('codigoCartorio', formulario.codigoCartorio)
    formData.append('qtdCompradores', formulario.qtdCompradores)
    formData.append('qtdVendedores', formulario.qtdVendedores)
    this.apiService.postApiEscrituras('escritura/save', formData).then(res => {

      this.solicitacoesServices.atualizarTela.next(true)
      this.loadingService.isActive = false
    }, err => {
      this.solicitacoesServices.atualizarTela.next(true)
      this.loadingService.isActive = false
    })
  }
}
