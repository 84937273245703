<mat-drawer-container class="example-container">
  <mat-drawer #drawer>
    <mat-list class="menu-open">
      <a *ngIf="tokenService.codigoCartorio == '94dcbdf5-1977-11eb-8252-0a96c6f995dd'" routerLink="/dashboard"
        rel="noopener noreferrer" (click)="drawer.toggle()">INÍCIO</a>
      <a routerLink="/ordens-pagamento" rel="noopener noreferrer" (click)="drawer.toggle()">ORDENS DE PAGAMENTO</a>
      <a [hidden]="tokenService.codigoCartorio == '94dcbdf5-1977-11eb-8252-0a96c6f995dd'" routerLink="/solicitacoes"
        rel="noopener noreferrer" (click)="drawer.toggle()">SOLICITAÇÕES</a>
      <a routerLink="/relatorios"
        rel="noopener noreferrer" (click)="drawer.toggle()">RELATÓRIOS</a>

      <!--   <a routerLink="/ordens-pagamento" rel="noopener noreferrer" (click)="drawer.toggle()">AGENDAMENTO</a>
      <a routerLink="/ordens-pagamento" rel="noopener noreferrer" (click)="drawer.toggle()">ESTORNO</a>
      <a routerLink="/ordens-pagamento" rel="noopener noreferrer" (click)="drawer.toggle()">PERFIL</a>
      <a routerLink="/configuracoes" rel="noopener noreferrer" (click)="drawer.toggle()">CONFIGURAÇÕES</a> -->
      <a routerLink="/login" rel="noopener noreferrer" (click)="sair()">SAIR</a>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar class="background-primary">
      <div class="d-flex w-100">
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
          (click)="drawer.toggle()">
          <mat-icon class="color-white">menu</mat-icon>
        </button>
        <div class="flex-grow-1">
          Inova Cartórios
        </div>
        <div class="icon">
          <mat-icon class="color-white pointer" (click)="openCloseNotifications()"
            matBadge="{{notificationService?.allNotification?.page.totalElements}}"
            [matBadgeHidden]="notificationService?.allNotification?.page.totalElements === 0" matBadgeColor="warn">
            notification_important</mat-icon>
          <!-- Include text description of the icon's meaning for screen-readers -->

        </div>
        <ul class="perfil">
          <li>{{ name }}</li>
          <li class="pointer" routerLink="/login" (click)="sair()">Sair</li>
        </ul>
      </div>
    </mat-toolbar>
    <app-notification class="notifier fade-in" *ngIf="notificationService.showNotification"></app-notification>

    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>