<form [formGroup]="profileForm" class="example-form" autocomplete="off" (ngSubmit)="submit()">

  <h1 class="title-section mt-30">ORIGEM DA SOLICITAÇÃO</h1>

  <mat-form-field class="example-half-width">
    <mat-select (selectionChange)="selectSolicitationType($event)" required formControlName="origem_solicitacao">
      <mat-option selected value="" disabled>Selecione</mat-option>
      <mat-option value="BALCAO">Balcão</mat-option>
      <mat-option value="OFICIO">Ofício</mat-option>
      <mat-option value="MENSALISTAS">Mensalistas</mat-option>
    </mat-select>
  </mat-form-field>

  <h1 class="title-section mt-30">DADOS DO ATO</h1>
  <p><i>Os campos marcados com * são obrigatórios</i></p>

  <form formGroupName="dados1">
    <div class="mt-30" *ngIf="buscaCepService.qtdSolicitacoes > 1">
      <h1 class="title-section">Solicitação 1</h1>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label>Nome da(s) Parte(s)</mat-label>
      <textarea formControlName="nome_partes" matInput value=""></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Tipo do Ato</mat-label>
      <input formControlName="tipo_ato" placeholder="Ex: Escritura, Procuração, Inventário, etc..." matInput value="">
    </mat-form-field>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Livro</mat-label>
        <input formControlName="livro_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml mr">
        <mat-label>Folha</mat-label>
        <input formControlName="folha_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Data do Ato</mat-label>
        <input formControlName="data_ato" type="date" placeholder="Ex: DD/MM/YYYY" matInput value="">
      </mat-form-field>
    </div>

  </form>

  <form formGroupName="dados2" *ngIf="buscaCepService.qtdSolicitacoes <= 5 && buscaCepService.qtdSolicitacoes > 1 ">
    <hr>

    <div class="mt-30">
      <h1 class="title-section">Solicitação 2</h1>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label>Nome da(s) Parte(s)</mat-label>
      <textarea formControlName="nome_partes" matInput value=""></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Tipo do Ato</mat-label>
      <input formControlName="tipo_ato" placeholder="Ex: Escritura, Procuração, Inventário, etc..." matInput value="">
    </mat-form-field>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Livro</mat-label>
        <input formControlName="livro_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml mr">
        <mat-label>Folha</mat-label>
        <input formControlName="folha_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Data do Ato</mat-label>
        <input formControlName="data_ato" type="date" placeholder="Ex: DD/MM/YYYY" matInput value="">
      </mat-form-field>
    </div>

  </form>

  <form formGroupName="dados3" *ngIf="buscaCepService.qtdSolicitacoes <= 5 && buscaCepService.qtdSolicitacoes > 2 ">
    <hr>

    <div class="mt-30">
      <h1 class="title-section">Solicitação 3</h1>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label>Nome da(s) Parte(s)</mat-label>
      <textarea formControlName="nome_partes" matInput value=""></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Tipo do Ato</mat-label>
      <input formControlName="tipo_ato" placeholder="Ex: Escritura, Procuração, Inventário, etc..." matInput value="">
    </mat-form-field>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Livro</mat-label>
        <input formControlName="livro_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml mr">
        <mat-label>Folha</mat-label>
        <input formControlName="folha_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Data do Ato</mat-label>
        <input formControlName="data_ato" type="date" placeholder="Ex: DD/MM/YYYY" matInput value="">
      </mat-form-field>
    </div>

  </form>

  <form formGroupName="dados4" *ngIf="buscaCepService.qtdSolicitacoes <= 5 && buscaCepService.qtdSolicitacoes > 3 ">
    <hr>

    <div class="mt-30">
      <h1 class="title-section">Solicitação 4</h1>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label>Nome da(s) Parte(s)</mat-label>
      <textarea formControlName="nome_partes" matInput value=""></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Tipo do Ato</mat-label>
      <input formControlName="tipo_ato" placeholder="Ex: Escritura, Procuração, Inventário, etc..." matInput value="">
    </mat-form-field>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Livro</mat-label>
        <input formControlName="livro_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml mr">
        <mat-label>Folha</mat-label>
        <input formControlName="folha_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Data do Ato</mat-label>
        <input formControlName="data_ato" type="date" placeholder="Ex: DD/MM/YYYY" matInput value="">
      </mat-form-field>
    </div>

  </form>


  <form formGroupName="dados5" *ngIf="buscaCepService.qtdSolicitacoes > 4 ">
    <hr>
    <div class="mt-30">
      <h1 class="title-section">Solicitação 5</h1>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label>Nome da(s) Parte(s)</mat-label>
      <textarea formControlName="nome_partes" matInput value=""></textarea>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Tipo do Ato</mat-label>
      <input formControlName="tipo_ato" placeholder="Ex: Escritura, Procuração, Inventário, etc..." matInput value="">
    </mat-form-field>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Livro</mat-label>
        <input formControlName="livro_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml mr">
        <mat-label>Folha</mat-label>
        <input formControlName="folha_ato" matInput value="">
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Data do Ato</mat-label>
        <input formControlName="data_ato" type="date" placeholder="Ex: DD/MM/YYYY" matInput value="">
      </mat-form-field>
    </div>
  </form>

  <div class="action">
    <button style="font-size: 14px;" *ngIf="buscaCepService.qtdSolicitacoes < 5" type="button" mat-raised-button
      color="primary" class="btn mt-30" (click)="addFields()">Adicionar solicitação</button>
    <button style="font-size: 14px" *ngIf="buscaCepService.qtdSolicitacoes > 1" type="button" mat-raised-button
      color="primary" class="btn mt-30" (click)="removeField()">Remover solicitação</button>

  </div>


  <hr>

  <h1 class="title-section mt-30">
    POSSUI UMA CÓPIA DO ATO ACIMA?
  </h1>

  <form class="form">
    <div class="file-upload-wrapper" data-text="Selecione um ou mais arquivos">
      <input name="file-upload-field" type="file" (change)="handleFileChange($event)" class="file-upload-field"
        multiple>
    </div>
  </form>
  <div class="files" *ngFor="let file of infoService.base64">
    <span>{{file.nome_arquivo}}</span>
    <mat-icon (click)="removeSelectedFile(file)">close</mat-icon>
  </div>
  <hr>


  <h1 class="title-section mt-30">
    FORMA DE ENTREGA
  </h1>

  <mat-radio-group required color="primary" aria-labelledby="example-radio-group-label" class="example-radio-group"
    [(ngModel)]="infoService.opcaoEntregaSelecionada" [ngModelOptions]="{standalone: true}">
    <mat-radio-button (click)="onClick(opcaoEntregaSelecionada)" class="example-radio-button"
      *ngFor="let opcaoEntregaSelecionada of infoService?.opcoesEntrega" [value]="opcaoEntregaSelecionada">
      {{opcaoEntregaSelecionada}}
    </mat-radio-button>
  </mat-radio-group>

  <div class="to-deliver" *ngIf="infoService.opcaoEntregaSelecionada === 'Entregar no endereço'">
    <p><i>Os campos marcados com * são obrigatórios</i></p>
    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>CEP *</mat-label>
        <input mask="00000-000" (keyup)="getCep(profileForm.get('cep').value)" formControlName="cep" matInput>
      </mat-form-field>
      <mat-form-field class="example-full-width ml mr">
        <mat-label>Endereço *</mat-label>
        <input formControlName="logradouro" matInput>
      </mat-form-field>
      <mat-form-field class="example-full-width ml">
        <mat-label>Número *</mat-label>
        <input formControlName="numero" matInput>
      </mat-form-field>
    </div>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Complemento</mat-label>
        <input formControlName="complemento" matInput>
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Bairro *</mat-label>
        <input formControlName="bairro" matInput>
      </mat-form-field>
    </div>

    <div class="d-flex">
      <mat-form-field class="example-full-width mr">
        <mat-label>Cidade *</mat-label>
        <input formControlName="cidade" matInput>
      </mat-form-field>

      <mat-form-field class="example-full-width ml">
        <mat-label>Estado *</mat-label>
        <input formControlName="uf" matInput>
      </mat-form-field>
    </div>
  </div>
  <hr>

  <h1 class="title-section mt-30">
    DADOS DO SOLICITANTE
  </h1>
  <p><i>Os campos marcados com * são obrigatórios</i></p>

  <div class="d-flex">
    <mat-form-field class="example-full-width mr">
      <mat-label>Nome Completo</mat-label>
      <input pattern="[a-z-A-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]* [a-z-A-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]*"
        required formControlName="nome" matInput>
    </mat-form-field>

    <div class="d-flex example-full-width" style="flex-direction: column;">
      <mat-form-field class="example-full-width ml">
        <mat-label>CPF ou CNPJ</mat-label>
        <input required formControlName="cpf_cnpj" on-focusout="cpfCnpjIsReal(profileForm.get('cpf_cnpj').value)"
          matInput mask="000.000.000-00||00.000.000/0000-00">
      </mat-form-field>
      <small style="left: 10px; position: relative; top: -15px; color: red;"> {{error.cpfCnpj.message}} </small>
    </div>

  </div>

  <div class="d-flex">
    <mat-form-field class="example-full-width mr">
      <mat-label>E-mail</mat-label>
      <input required formControlName="email" matInput>
    </mat-form-field>
    <div class="d-flex example-full-width" style="flex-direction: column;">

      <mat-form-field class="example-full-width ml">
        <mat-label>Telefone/Celular</mat-label>
        <input required on-focusout="phoneNumberIsReal(profileForm.get('telefone').value)" formControlName="telefone" matInput mask="(00) 0000-0000 || (00) 00000-0000">
      </mat-form-field>
      <small style="left: 10px; position: relative; top: -15px; color: red;"> {{error.phone.message}} </small>
    </div>
  </div>

  <mat-form-field class="example-full-width">
    <mat-label>Mensagem</mat-label>
    <textarea required formControlName="mensagem" matInput placeholder="Digite uma mensagem"></textarea>
  </mat-form-field>
  <hr>
  <div class="action-bottom">
    <div>
      <h1 class="title-section mt-30">
        VALORES
      </h1>

      <p>Valor do serviço: {{buscaCepService?.valorServico | currency:'BRL':true}}</p>
      <div *ngIf="profileForm.get('entrega').value == 2">
        <p>Frete: {{buscaCepService?.frete | currency:'BRL':true}}</p>
        <p>Total: {{buscaCepService?.valorTotal | currency:'BRL':true}}</p>
      </div>

    </div>
    <div class="text-right">
      <button [disabled]="profileForm.invalid || error.cpfCnpj.message != '' 
      || error.phone.message != '' " mat-raised-button color="primary" class="btn">ENVIAR
        SOLICITAÇÃO</button>
      <p *ngIf="profileForm.invalid || error.cpfCnpj.message != '' 
      || error.phone.message != '' " style="margin-top: 5px;" style="margin-top: 5px;"><i>Confirme se todas as
          informações
          foram
          preenchidas</i>
      </p>
    </div>
  </div>
</form>