import { Component, ChangeDetectorRef } from '@angular/core';
import { LoginService } from './services/login.service';
import { LoadingService } from './services/loading.service';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './utils/animations/animation';
import { NotificationService } from './services/notification.service';
import { TokenService } from './services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent {
  title = 'inova-pagamentos';

  get loading() {
    return this.loadingService.isActive
  }
  constructor(
    public loginService: LoginService,
    public loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
   /*  if (this.tokenService?.codigoCartorio) {
      this.notificationService.getAllNotification(0, 5)
      setInterval(() => {
        if (this.notificationService.showNotification === false) {
          this.notificationService.getAllNotification(0, 5)
        } else {
          this.notificationService.getAllNotification(this.notificationService.currentPage, this.notificationService.size)
        }
      }, 60 * 1000)
    } */
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
