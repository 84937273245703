import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { OrdemPagadorInterface } from '../models/OrdemPagador';
import { getPeriodoDefaultISO } from '../utils/DateUtils';
import { PagadorInterface } from '../models/Pagador';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import IResponseSolicitacao, { ISolicitante, ISolitacao } from '../models/solicitacoes';
import { ITableSolicitacao } from '../models/ITableSolicitacao';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from './loading.service';

interface codigoPedido {

  codigo_pedido: string,
  valor: number,
  cpf_pagador: string,
  codigo_status: number,
  descricao_status: string,
  nome: string,
  email: string,
  telefone: string

}

const API_URL = environment.url

@Injectable({
  providedIn: 'root',
})
export class PagadorService {

  codigo = ''
  pagador_result = []

  constructor(private http: HttpClient, private tokenService: TokenService, public apiService: ApiService, private route: ActivatedRoute, public loadingService: LoadingService
  ) { }

  getPagadoresPorOrdem(codigo_ordem) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
            }),
          };

          this.http
            .get<any[]>(
              `${API_URL}/ordenspgto/${codigo_ordem}/pagadores`,
              httpOptions
            )
            .subscribe(
              (pagadores) => {
                this.pagador_result = pagadores
                let pagadoresitc: OrdemPagadorInterface[] = [];
                pagadores.forEach((p) => {
                  pagadoresitc.push({
                    pagador: {
                      cpf: p.cpf_pagador,
                      nome: p.nome,
                      telefone: p.telefone,
                      email: p.email,
                    },
                    valor: p.valor,
                    codigo_pedido: p.codigo_pedido,
                    codigo_status: p.codigo_status,
                    descricao_status: p.descricao_status
                  });
                });
                resolve(pagadoresitc);
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getPagadorPorCPF(cpf) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
            }),
          };

          this.http
            .get<any[]>(
              `${API_URL}/pagadores?cpf=${cpf}`,
              httpOptions
            )
            .subscribe(
              (pagadores) => {
                if (pagadores.length > 0) {
                  let pagador: PagadorInterface = {}
                  pagador = {
                    cpf: pagadores[0]['cpf'],
                    nome: pagadores[0]['nome'],
                    telefone: pagadores[0]['telefone'],
                    email: pagadores[0]['email'],
                  }
                  resolve(pagador);
                } else {
                  reject(false)
                }
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  refund(cpf) {
    let c1 = cpf.replaceAll('.', '')
    let c2 = c1.replaceAll('-', '')
    let c3 = c2.replaceAll('/', '')
    this.apiService.cpfPorPagador = c3
    this.loadingService.isActive = true

    this.apiService.getApi('ordenspgto/' + this.codigo + '/pagadores').then((res: codigoPedido) => {
      this.loadingService.isActive = false

      this.apiService.postEstorno(res[0].codigo_pedido, '').then(res2 => {
        this.loadingService.isActive = false

      }, err => {
        this.loadingService.isActive = false
      })
    }, err => {
      this.loadingService.isActive = false
    })
  }

  associatePagadorOrdem(codigo: number, ordemPagador: OrdemPagadorInterface) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
            }),
          };

          this.http
            .put<any[]>(
              `${API_URL}ordenspgto/${codigo}/pagadores`,
              ordemPagador,
              httpOptions
            )
            .subscribe(
              (result) => {
                resolve(result);
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  dessasociarPagador(codigo_ordem: number, cpf_pagador: string) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
            }),
          };

          this.http
            .delete<any[]>(
              `${API_URL}/ordenspgto/${codigo_ordem}/${cpf_pagador}`,
              httpOptions
            )
            .subscribe(
              (result) => {
                resolve(result);
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
