import { Component, OnInit, Inject, } from '@angular/core';
import { OrdemService } from '../services/ordem.service';
import { OrdemInterface } from '../models/Ordem';
import { OrdemPagadorInterface } from '../models/OrdemPagador';
import { ActivatedRoute } from '@angular/router';
import { toBRDate } from '../utils/DateUtils';
import { PagadorService } from '../services/pagador.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { isValidCpf } from '../utils/Validators';
import { conformToMask } from 'angular2-text-mask'
import { NovoPagadorComponent } from '../novo-pagador/novo-pagador.component';
import { InfoService } from '../services/info.service';
import { LoadingService } from '../services/loading.service';
import { SolicitacoesService } from '../services/solicitacoes.service';
import { MaskApplierService } from 'ngx-mask';
import { DatePipe } from '@angular/common';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmarFinalizacaoComponent } from './components/confirmar-finalizacao/confirmar-finalizacao.component';


@Component({
  selector: 'app-ordem-detalhe',
  templateUrl: './ordem-detalhe.component.html',
  styleUrls: ['./ordem-detalhe.component.scss'],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    NgbAlertConfig
  ],

})

export class OrdemDetalheComponent implements OnInit {


  isEditingOrdem = false
  displayedColumns: string[] = ['name', 'cpf', 'email', 'telefone', 'status', 'valor']
  panelOpenState: boolean = false
  novoPagador: boolean = false
  codigo: number = 0
  ordem: OrdemInterface = {}
  pagadores: OrdemPagadorInterface[] = []
  pagadorForm: FormGroup = null
  disableFields: boolean = true
  enableInputs: boolean = false
  cpfsearched: string = '';
  total_declarado = '';
  total_offset = '';
  isEditingPagador: boolean = false;
  editingOrdem: OrdemPagadorInterface = {}
  ordemForm: FormGroup = null;

  getMaskPhone(phone) {
    return phone.length <= 10 ? '(00) 0000-00009' : '(00) 00000-0000'
  }


  isCPF(cpf_cnpj): string {
    return cpf_cnpj.length < 12 ? '000.000.000-00' : '00.000.000/0000-00';
  }

  cpf_mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]

  phone_mask = function (rawValue) {
    rawValue += '';
    rawValue = rawValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\ /g, '').replace(/\-/g, '').replace(/\_/g, '')
    if (rawValue.length <= 10) {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    } else {
      return ['(', /[0-9]/, /\d/, ')', ' ', /\d|''/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }
  }

  money_mask = function (rawValue) {
    rawValue += '';
    rawValue = rawValue.replace(/\./g, '').replace(/\,/g, '')
    rawValue = rawValue.replace(/\-/g, '');
    let mask = [];
    let ct = 0;
    for (var x = rawValue.length; x > 0; x--) {
      if (x == rawValue.length) {
        mask.push(/[0-9]/)
      } else if (x == (rawValue.length - 1)) {
        mask.push(/[0-9]/)
      } else {
        if (x == (rawValue.length - 2)) {
          mask.push(',')
        }
        if (ct === 3) {
          mask.push('.')
          ct = 0;
        }
        ct++
        mask.push(/[0-9]/)

      }
    }
    return mask.reverse()
  }

  finishedLoading: number = 0;


  constructor(
    alertConfig: NgbAlertConfig,
    private route: ActivatedRoute,
    public ordemService: OrdemService,
    private pagadorService: PagadorService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public infoService: InfoService,
    private loadingService: LoadingService,
    public solicitacaoService: SolicitacoesService,
    private _maskService: MaskApplierService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    alertConfig.type = 'success';
    alertConfig.dismissible = false;

  }

  ngOnInit(): void {
    this.infoService.enableInputs = false
    this.loadingService.isActive = true;
    this.codigo = this.route.snapshot.params.codigo;
    this.infoService.codigo = this.route.snapshot.params.codigo
    this.pagadorService.codigo = this.route.snapshot.params.codigo
    this.getOrder()
    this.loadOrdem()
    this.pagadorForm = this.formBuilder.group({
      cpf: ['', [Validators.required, isValidCpf]],
      nome: [{ value: '', disabled: this.disableFields }, Validators.required],
      email: [{ value: '', disabled: this.disableFields }, Validators.email],
      telefone: [{ value: '', disabled: this.disableFields }, Validators.required],
      valor: ['', Validators.required],
    })
    this.ordemService.atualizarTela.subscribe(res => {
      if (res) {
        this.loadOrdem()
      }
    })    
  }

  getOrder() {
    if (this.data) {
      this.ordem = this.data;
      this.ordemForm = this.formBuilder.group({
        numero_nota_ato: [this.data.numero_nota_ato, Validators.required],
        data_lavratura_ato: [new Date(this.data.data_lavratura), Validators.required],
        livro_ato: [this.data.livro_ato, Validators.required],
        folha_ato: [this.data.folha_ato, Validators.required],
        valor_total: [this.data.valor_total, Validators.required]
      })
    } else {
      this.ordemForm = this.formBuilder.group({
        numero_nota_ato: ['', Validators.required],
        data_lavratura_ato: ['', Validators.required],
        livro_ato: ['', Validators.required],
        folha_ato: ['', Validators.required],
        valor_total: ['', Validators.required],
      })
    }
  }

  openNovoPagador(solicitacao) {
    this.infoService.isNewPayer = true
    const dialogRef = this.dialog.open(NovoPagadorComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  loadOrdem() {
    this.loadingService.isActive = true;
    this.ordemService
      .getOrdem(this.codigo)
      .then((ordem: OrdemInterface) => {
        ordem.data_lavratura_display = toBRDate(ordem.data_lavratura);
        this.ordem = ordem;
        this.infoService.ordemPagador = ordem;
        this.finishedLoading++
        this.infoService.finishedLoading++
        if (this.finishedLoading >= 2) {
          this.loadTotais()
        }
        if (this.solicitacaoService.solicitacaoSelecionada != null) {
          const phone = this.solicitacaoService.solicitacaoSelecionada.solicitante.telefone
          const cpfCnpj = this.solicitacaoService.solicitacaoSelecionada.solicitante.cpf_cnpj
          const ordemPagador: OrdemPagadorInterface = {
            ordem: ordem,
            pagador: {
              cpf: this._maskService.applyMask(cpfCnpj, this.isCPF(cpfCnpj)),
              email: this.solicitacaoService.solicitacaoSelecionada.solicitante.email,
              nome: this.solicitacaoService.solicitacaoSelecionada.solicitante.nome,
              telefone: this._maskService.applyMask(phone, this.getMaskPhone(phone)),
              valor: this.solicitacaoService.solicitacaoSelecionada.value
            },
            codigo_pedido: this.solicitacaoService.solicitacaoSelecionada.codigo,
            codigo_status: this.solicitacaoService.solicitacaoSelecionada.status.codigo,
            descricao_status: this.solicitacaoService.solicitacaoSelecionada.status.descricao,
            valor: Number(this.solicitacaoService.solicitacaoSelecionada.value),
            codigo: this.solicitacaoService.solicitacaoSelecionada.codigo
          }          
          this.novaOrdemComPagador(ordemPagador)
        } else {
          this.solicitacaoService.solicitacaoSelecionada = null
          this.loadingService.isActive = false;
        }
      })
      .catch((err) => {
        this.loadingService.isActive = false;
      });
    this.infoService.loadPagadores()

  }

  sumValues() {
    let total_declarado: number = 0
    this.infoService.pagadores.forEach(element => {
      total_declarado += Number(element.valor)
    });
    return total_declarado

  }

  loadTotais() {

    let valor_declarado = 0
    this.pagadores.forEach(p => {
      valor_declarado += this.parseMoney(p.valor)
    })
    let count = (valor_declarado - this.parseMoney(this.ordem.valor_total))
    let offsetNegative = count < 0 ? '-' : '+';
    let offset = count + ''
    if (offset.indexOf('.') === -1) {
      offset += '00'
    } else {
      offset = offset.split('.')[0] + offset.split('.')[1].padEnd(2, '0')
    }

    let declared = valor_declarado + ''
    if (declared.indexOf('.') === -1) {
      declared += '00'
    } else {
      declared = declared.split('.')[0] + declared.split('.')[1].padEnd(2, '0')
    }

    this.total_declarado = 'R$ ' + conformToMask(declared, this.money_mask, { guide: false }).conformedValue
    this.total_offset = offsetNegative + 'R$ ' + conformToMask(offset, this.money_mask, { guide: false }).conformedValue
  }

  novoPagadorBtn() {
    this.calculateValue()
    this.novoPagador = true;
  }

  associate() {
    if (this.pagadorForm.invalid) {
      this._snackBar.open('Por favor, preencha os campos', 'Ok', {
        duration: 4000,
        panelClass: ['error-snackbar'],
      });
      return;
    }
    if (this.isEditingPagador) {
      this.loadingService.isActive = true
      this.pagadorService.dessasociarPagador(this.ordem.codigo, this.editingOrdem.pagador.cpf)
        .then((result) => {
          let ordemPagadorInterface: OrdemPagadorInterface = {};
          ordemPagadorInterface.ordem = this.ordem;
          ordemPagadorInterface.pagador = this.pagadorForm.value;
          ordemPagadorInterface.valor = this.pagadorForm.get('valor').value;
          ordemPagadorInterface.codigo_pedido = '0';

          this.pagadorService.associatePagadorOrdem(this.ordem.codigo, ordemPagadorInterface).then((result) => {
            this.pagadores.push(ordemPagadorInterface);
            this.loadTotais()
            this.novoPagador = false;
            this.pagadorForm.reset();
            this.loadingService.isActive = false;
          })
            .catch((err) => {
              this.loadingService.isActive = false;
              this._snackBar.open(
                'Ocorreu um erro, por favor tente novamente mais tarde',
                'Ok',
                {
                  duration: 4000,
                  panelClass: ['error-snackbar'],
                }
              );
            });
        })
        .catch((err) => {
          this.loadingService.isActive = false;
          this._snackBar.open(
            'Ocorreu um erro, por favor tente novamente mais tarde',
            'Ok',
            {
              duration: 4000,
              panelClass: ['error-snackbar'],
            }
          );
        });
    } else {
      this.loadingService.isActive = true;
      let ordemPagadorInterface: OrdemPagadorInterface = {};
      ordemPagadorInterface.ordem = this.ordem;
      ordemPagadorInterface.pagador = this.pagadorForm.value;
      ordemPagadorInterface.valor = this.pagadorForm.get('valor').value;
      ordemPagadorInterface.codigo_pedido = '0';

      this.pagadorService
        .associatePagadorOrdem(this.ordem.codigo, ordemPagadorInterface)
        .then((result) => {
          this.pagadores.push(ordemPagadorInterface);
          this.loadTotais()
          this.novoPagador = false;
          this.pagadorForm.reset();
          this.loadingService.isActive = false;
        })
        .catch((err) => {
          this.loadingService.isActive = false;
          this._snackBar.open(
            'Ocorreu um erro, por favor tente novamente mais tarde',
            'Ok',
            {
              duration: 4000,
              panelClass: ['error-snackbar'],
            }
          );
        });
    }
  }

  novaOrdemComPagador(info: OrdemPagadorInterface) {
    info.pagador.cpf = info.pagador.cpf.replace(/\D/g, '')
    this.pagadorService
      .associatePagadorOrdem(this.ordem.codigo, info)
      .then((result) => {
        this.solicitacaoService.solicitacaoSelecionada = null
        this.loadingService.isActive = true
        this.loadOrdem()
      })
  }

  alterarPagador(ordemPagador: OrdemPagadorInterface) {
    this.infoService.isNewPayer = false
    this.dialog.open(NovoPagadorComponent)
    this.infoService.selectedPayer = ordemPagador
    this.editingOrdem = ordemPagador;
  }

  cancelAlterar() {
    this.pagadores.push(this.editingOrdem)
    this.novoPagador = false
    this.isEditingPagador = false
  }

  scrollToBottom(): void {
    try {
      window.scrollTo(0, document.body.scrollHeight);
    } catch (err) { }
  }

  dettachPagador(cpf_pagador) {
    this.loadingService.isActive = true;
    const ifen = cpf_pagador.replaceAll('-', '')
    const barra = ifen.replaceAll('/', '')
    const cpf = barra.replaceAll('.', '')
    this.pagadorService
      .dessasociarPagador(this.ordem.codigo, cpf)
      .then((result) => {
        this.infoService.loadPagadores();
        this.loadOrdem()

      })
      .catch((err) => {
        this.loadingService.isActive = false;
        this._snackBar.open(
          'Ocorreu um erro, por favor tente novamente mais tarde',
          'Ok',
          {
            duration: 4000,
          }
        );
      });
  }

  openDialog(pagador): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: '240px',
      data: { nome: pagador.nome, cpf: pagador.cpf , solicitacao: this.infoService.pagadores[0].codigo_pedido}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dettachPagador(result)
      }
    });
    this.loadOrdem()
  }

  parseMoney(txt) {
    txt = txt.replace(/\./g, '').replace(/\,/, '.')
    return parseFloat(txt)
  }

  calculateValue() {

    let original = this.parseMoney(this.ordem.valor_total)
    let valor_declarado = 0
    this.pagadores.forEach(p => {
      valor_declarado += this.parseMoney(p.valor)
    })
    let count = (original - valor_declarado).toString();
    if (count.indexOf('.') === -1) {
      count += '00'
    } else {
      count = count.split('.')[0] + count.split('.')[1].padEnd(2, '0')
    }
    count = count.replace(/\./g, '').replace(/\,/g, '')
    let setVal = conformToMask(
      count,
      this.money_mask,
      { guide: false }
    ).conformedValue
    this.pagadorForm.get('valor').setValue(setVal)
  }

  openConfirm(pagador) {
    this.ordemService.pagador = pagador
    const dialogRef = this.dialog.open(ConfirmarFinalizacaoComponent, {
      width: '500px',
    });
 
    /* 
        this.loadOrdem();
    
        this.ordemForm = this.formBuilder.group({
          numero_nota_ato: [this.ordem.numero_nota_ato, Validators.required],
          data_lavratura_ato: [this.ordem.data_lavratura_display, Validators.required],
          livro_ato: [this.ordem.livro_ato, Validators.required],
          folha_ato: [this.ordem.folha_ato, Validators.required],
          valor_total: [this.ordem.valor_total, Validators.required]
        })
    
        this.infoService.enableInputs = true */
  }

  closeEdit() {
    this.infoService.enableInputs = false
  }

  atualizarOrdem() {
    const date = new Date(Number(this.ordem.data_lavratura_display.substr(6, 4)), Number(this.ordem.data_lavratura_display.substr(3, 2)) - 1, Number(this.ordem.data_lavratura_display.substr(0, 2)))
    const data = {
      ...this.ordemForm.value,
      data_lavratura_ato: this.datePipe.transform(date, 'yyyy-MM-dd')
    }

    this.loadingService.isActive = true;
    this.ordemService.atualizarOrdem(this.ordem.codigo, data)
      .then((results: any) => {
        this.loadingService.isActive = false;
        this.infoService.callBackOrdemDetalhe = this.ordem.codigo
        this.loadOrdem();
        this.infoService.enableInputs = false
      })
      .catch(err => {
        this.loadingService.isActive = false;
        this._snackBar.open('Ocorreu um erro, por favor tente mais tarde', 'Ok', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
      })
  }

  cpfChange(value) {
    let rawValue = value;
    if (this.cpfsearched !== rawValue) {
      this.cpfsearched = rawValue;
      value = value.replace(/\./g, '').replace(/\-/g, '').replace(/\_/g, '')
      if (value.length == 11 && this.pagadorForm.get('cpf').valid) {
        this.loadingService.isActive = true
        this.pagadorService.getPagadorPorCPF(rawValue)
          .then((pagador: any) => {
            this.pagadorForm.get('nome').setValue(pagador.nome)
            this.pagadorForm.get('nome').enable()
            this.pagadorForm.get('telefone').setValue(pagador.telefone)
            this.pagadorForm.get('telefone').enable()
            this.pagadorForm.get('email').setValue(pagador.email)
            this.pagadorForm.get('email').enable()
            this.disableFields = false;
            this.loadingService.isActive = false;
          })
          .catch(err => {
            this.pagadorForm.get('nome').setValue('')
            this.pagadorForm.get('telefone').setValue('')
            this.pagadorForm.get('email').setValue('')
            this.pagadorForm.get('nome').enable()
            this.pagadorForm.get('telefone').enable()
            this.pagadorForm.get('email').enable()
            this.disableFields = false;
            this.loadingService.isActive = false;
          })
      } else {
        this.pagadorForm.get('nome').setValue('')
        this.pagadorForm.get('telefone').setValue('')
        this.pagadorForm.get('email').setValue('')
        this.pagadorForm.get('nome').disable()
        this.pagadorForm.get('telefone').disable()
        this.pagadorForm.get('email').disable()
      }
    }
  }

}
