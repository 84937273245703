<div class="inova-dialog">
    <h2 class="inova-dialog-title" mat-dialog-title>Remover Pagador</h2>
    <div class="inova-dialog-content" mat-dialog-content>
        <p>Confirma exclusão do pagador abaixo?</p>
        <p class="content">{{data.cpf | mask: data.cpf.length <= 11 ? '000.000.000-00' : '00.000.000/0000-00'}} - {{data.nome}}</p>
    </div>
    <div class="inova-dialog-actions" mat-dialog-actions>
        <button class="btn" mat-raised-button color="primary" (click)="onNoClick()">Cancelar</button>
        <button class="btn" mat-raised-button color="primary" (click)="onClick(data.cpf)">Confirmar</button>
    </div>
</div>