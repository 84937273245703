import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdemInterface } from '../models/Ordem';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { toBRDate, getPeriodoDefault } from '../utils/DateUtils';
import { MatDialog } from '@angular/material/dialog';
import { NovaOrdemComponent } from '../nova-ordem/nova-ordem.component';
import { OrdemService } from '../services/ordem.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from '../utils/date.config';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

import { isValidCpf } from '../utils/Validators';
import textMask, { conformToMask } from 'angular2-text-mask'
import { LoadingService } from '../services/loading.service';
import { SolicitacoesService } from '../services/solicitacoes.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-ordens-pagamento',
  templateUrl: './ordens-pagamento.component.html',
  styleUrls: ['./ordens-pagamento.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class OrdensPagamentoComponent implements OnInit {


  ordens: OrdemInterface[]
  dataSource
  searchForm: FormGroup = null

  cpf_mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]

  periodoDefault: {} = getPeriodoDefault();
  pesquisa = {
    periodoDe: this.periodoDefault['periodoDe'],
    periodoAte: this.periodoDefault['periodoAte'],
    numero_nota_ato: '',
    pagador: '',
  };

  tipoPesquisa: string = 'periodo';

  money_mask = function (rawValue) {
    rawValue += '';
    rawValue = rawValue.replace(/\./g, '').replace(/\,/g, '')
    let mask = [];
    let ct = 0;
    for (var x = rawValue.length; x > 0; x--) {
      if (x == rawValue.length) {
        mask.push(/[0-9]/)
      } else if (x == (rawValue.length - 1)) {
        mask.push(/[0-9]/)
      } else {
        if (x == (rawValue.length - 2)) {
          mask.push(',')
        }
        if (ct === 3) {
          mask.push('.')
          ct = 0;
        }
        ct++
        mask.push(/[0-9]/)

      }
    }
    return mask.reverse()
  }

  constructor(
    public dialog: MatDialog,
    private ordemService: OrdemService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private solicitacoesService: SolicitacoesService
  ) { }



  displayedColumns: string[] = [
    'numero_nota_ato',
    'codigo_ordem',
    'data_lavratura',
    'livro_ato',
    'folha_ato',
    'valor_total',
    'action',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = "Itens por páginas";
    this.searchForm = this.formBuilder.group({
      periodoDe: [this.periodoDefault['periodoDe']],
      periodoAte: [this.periodoDefault['periodoAte']],
      numero_nota_ato: [''],
      pagador: ['']
    });
    if (this.solicitacoesService.solicitacaoSelecionada) {
      this.openDialog(this.solicitacoesService.solicitacaoSelecionada)
    }
    this.loadOrdens();
  }

  loadOrdens() {
    this.searchForm.value.pagador = this.searchForm.value.pagador.replace(/[^0-9]+/g, '');
    this.loadingService.isActive = true
    this.dataSource = null;
    this.ordens = [];
    this.ordemService
      .getOrdens(this.tipoPesquisa, this.searchForm.value)
      .then((ordens: OrdemInterface[]) => {
        this.ordens = ordens.map((ordem) => {
          ordem.data_lavratura = toBRDate(ordem.data_lavratura);
          ordem.codigo_solicitacao = ordem.codigo_solicitacao
          return ordem;
        });
        this.ordens.forEach(function (item, index, object) {
          if (item.descricao_status === 'Cancelada' ) {
            object.splice(index, 1);
          }
        });
        this.dataSource = new MatTableDataSource<OrdemInterface>(this.ordens);
        this.dataSource.paginator = this.paginator;
        this.loadingService.isActive = false;
      })
      .catch((err) => {
        this.loadingService.isActive = false;
      });
  }

  clickPesquisar() {
    if (this.searchForm.valid) this.loadOrdens();
  }

  tipoPesquisaChange(event) {
    this.tipoPesquisa = event.target.value;
    try {
      this.pesquisa.periodoDe = this.searchForm.get('periodoDe').value;
    } catch (e) { }
    try {
      this.pesquisa.periodoAte = this.searchForm.get('periodoAte').value;
    } catch (e) { }
    try {
      this.pesquisa.numero_nota_ato = this.searchForm.get('numero_nota_ato').value;
    } catch (e) { }
    try {
      this.pesquisa.pagador = this.searchForm.get('pagador').value;
    } catch (e) { }

    switch (this.tipoPesquisa) {
      case 'periodo':
        this.searchForm.removeControl('numero_nota_ato');
        this.searchForm.removeControl('pagador');
        this.searchForm.addControl('periodoDe', new FormControl(this.pesquisa.periodoDe, Validators.required));
        this.searchForm.addControl('periodoAte', new FormControl(this.pesquisa.periodoAte, Validators.required));
        break;
      case 'numero_nota_ato':
        this.searchForm.removeControl('periodoDe');
        this.searchForm.removeControl('periodoAte');
        this.searchForm.removeControl('pagador');
        this.searchForm.addControl('numero_nota_ato', new FormControl(this.pesquisa.numero_nota_ato, Validators.required));
        break;

      case 'pagador':
        this.searchForm.removeControl('periodoDe');
        this.searchForm.removeControl('periodoAte');
        this.searchForm.removeControl('numero_nota_ato');
        this.searchForm.addControl('pagador', new FormControl(this.pesquisa.pagador, [Validators.required, isValidCpf]));
        break;
    }
  }

  openDialog(data = null): void {
    const dialogRef = this.dialog.open(NovaOrdemComponent, {
      width: '700px',
      height: 'fit-content',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.solicitacoesService.solicitacaoSelecionada = null
      }
    });
  }
}
