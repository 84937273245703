import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdensPagamentoComponent } from '../ordens-pagamento/ordens-pagamento.component';
import { OrdemInterface } from '../models/Ordem';
import { OrdemService } from '../services/ordem.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { LoadingService } from '../services/loading.service';
import { SolicitacoesService } from '../services/solicitacoes.service';
import { InfoService } from '../services/info.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-nova-ordem',
  templateUrl: './nova-ordem.component.html',
  styleUrls: ['./nova-ordem.component.scss'],
  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NovaOrdemComponent implements OnInit {

  ordem: OrdemInterface = {};
  ordemForm: FormGroup = null;
  isSubmitted = false;
  success_warn: boolean = false;

  isEditing: boolean = false

  constructor(
    public dialogRef: MatDialogRef<OrdensPagamentoComponent>,
    private ordemService: OrdemService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data,
    public solicitacoesService: SolicitacoesService,
    public infoService: InfoService,
  ) { }


  ngOnInit(): void {
    if (this.data) {
      this.isEditing = true;
      this.ordem = this.data;
      let frete = Number(this.data.valor_frete)
      let valor_solicitacao = Number(this.data.valor_solicitacao)
  
      if (this.data.codigo_ordem === 0) {
        if (this.ordem.canal === '1') {
          this.ordemForm = this.formBuilder.group({
            canal: 1,
            numero_nota_ato: [this.data.codigo_simples, Validators.required],
            data_lavratura_ato: [new Date(), Validators.required],
            livro_ato: [this.data.dados[0].livro_ato],
            folha_ato: [this.data.dados[0].folha_ato],
            valor_total: [(frete + valor_solicitacao).toFixed(2), [Validators.required]],
            codigo_solicitacao: [this.data.codigo]
          })
        } else {
          this.ordemForm = this.formBuilder.group({
            canal: 2,
            numero_nota_ato: [this.data.codigo_simples, Validators.required],
            data_lavratura_ato: [new Date(), Validators.required],
            livro_ato: [this.data.dados[0].livro_ato],
            folha_ato: [this.data.dados[0].folha_ato],
            valor_total: [(frete + valor_solicitacao).toFixed(2), [Validators.required]],
            codigo_solicitacao: [this.data.codigo]
          })
        }

      } else {
        this.router.navigate([`ordem-detalhe/${this.data.codigo_ordem}`])
        this.dialogRef.close()
      }

    } else {
      this.ordemForm = this.formBuilder.group({
        canal: 1,
        numero_nota_ato: ['', Validators.required],
        data_lavratura_ato: ['', Validators.required],
        livro_ato: [''],
        folha_ato: [''],
        valor_total: [null, Validators.required],
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  novaOrdem() {
    this.ordemForm.reset();
    this.success_warn = false;
  }

  cadastrarOrdem() {
    this.isSubmitted = true;
    if (!this.success_warn) {
      if (this.ordemForm.invalid) {
        this._snackBar.open('Por favor, preencha os campos', 'Ok', {
          duration: 4000,
          panelClass: ['error-snackbar']
        });
        return;
      }
      this.loadingService.isActive = true;
      this.ordemService.cadastrarOrdem(this.ordemForm.value).then((results: any) => {

        if (this.solicitacoesService.solicitacaoSelecionada) {
          this.solicitacoesService.solicitacaoSelecionada.value = this.ordemForm.get('valor_total').value
        }
        this.loadingService.isActive = false;
        this.success_warn = true;
        let insertId = results.insertId;
        this.router.navigate([`ordem-detalhe/${insertId}`])
        this.dialogRef.close(true);
      })
        .catch(err => {
          this.loadingService.isActive = false;
          this._snackBar.open('Ocorreu um erro, por favor tente mais tarde', 'Ok', {
            duration: 4000,
            panelClass: ['error-snackbar']
          });
        })
    }
  }
}
