import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { OrdemInterface } from '../models/Ordem';
import { getPeriodoDefaultISO } from '../utils/DateUtils';
import { ApiService } from './api.service'
import { InfoService } from './info.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { BehaviorSubject } from 'rxjs';
const API_URL = environment.url

interface pedidoEmail {
  codigo_pedido: string,
  pagador: {
    nome: string;
    email: string;
    cpf: string;
    telefone: string;
    valor: number;
  },
  valor: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrdemService {

  emailEnviado = false
  error = false
  detalhesOrdem
  pagador
  public atualizarTela = new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient,
    private tokenService: TokenService,
    private apiService: ApiService,
    private infoService: InfoService,
    private loadingService: LoadingService) { }


  getOrdens(tipoPesquisa, pesquisa) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              'cartorio': this.tokenService.codigoCartorio
            }),
          };

          let params = '';

          switch (tipoPesquisa) {
            case 'periodo':
              if (pesquisa.periodoDe && pesquisa.periodoAte) {
                params = `periodoDe=${pesquisa.periodoDe.toISOString()}&periodoAte=${pesquisa.periodoAte.toISOString()}`;
              } else {
                var periodo: any = getPeriodoDefaultISO();
                params = `periodoDe=${periodo.periodoDe}&periodoAte=${periodo.periodoAte}`;
              }
              break;
            case 'numero_nota_ato':
              params = `numero_nota_ato=${pesquisa.numero_nota_ato}`;
              break;
            case 'pagador':
              params = `pagador=${pesquisa.pagador}`;
              break;
          }

          this.http
            .get<OrdemInterface[]>(
              `${API_URL}ordenspgto?${params}`,
              httpOptions
            )
            .subscribe(
              (ordens) => {
                resolve(ordens);
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateStatusOrdem(message, cpf) {
    this.loadingService.isActive = true
    let body = {
      codigo: 3,
      obs: message
    }
    this.apiService.putApi('/ordenspgto/' + this.infoService.ordemPagador.codigo + '/pagadores/' + cpf + '/status', body).then(res => {
      this.loadingService.isActive = false
      this.atualizarTela.next(true)
    }, err => {
      this.loadingService.isActive = false
    })
  }

  getOrdem(codigo: number) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              'cartorio': this.tokenService.codigoCartorio
            }),
          };

          this.http
            .get<OrdemInterface[]>(
              `${API_URL}ordenspgto/${codigo}`,
              httpOptions
            )
            .subscribe(
              (ordens) => {
                resolve(ordens[0]);
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  cadastrarOrdem(ordem) {

    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token,
              'cartorio': this.tokenService.codigoCartorio
            }),
          };

          this.http
            .post<OrdemInterface[]>(`${API_URL}/ordenspgto`, ordem, httpOptions)
            .subscribe(
              (result) => {
                resolve(result);
              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  gerarPedido(info, valor) {
    this.infoService.clicked = true
    const cpf = info.cpf.replace(/\./g, '')
    this.loadingService.isActive = true
    const body = {
      codigo_pedido: '0',
      pagador: {
        nome: info.nome,
        email: info.email,
        telefone: info.telefone,
        cpf: cpf,
      },
      valor: Number(valor)
    }

    this.apiService.postApi('ordenspgto/' + this.infoService.codigo + '/envio', body).then((pedido: pedidoEmail) => {
      this.loadingService.isActive = false
      this.emailEnviado = true
      this.atualizarTela.next(true)
      setTimeout(() => {
        this.emailEnviado = false
      }, 5000);
    }, err => {
      this.loadingService.isActive = false
      this.error = true
      setTimeout(() => {
        this.error = false
      }, 5000);
    })
  }

  atualizarOrdem(codigo_ordem, ordem) {
    return new Promise((resolve, reject) => {
      this.tokenService.getToken().subscribe(
        (tkn) => {
          let token = tkn['access_token'];

          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token
            }),
          };

          this.http
            .put<OrdemInterface[]>(`${API_URL}/ordenspgto/${codigo_ordem}`, ordem, httpOptions)
            .subscribe(
              (result) => {
                resolve(result);

              },
              (err) => {
                reject(err);
              }
            );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
