import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrdemService } from 'src/app/services/ordem.service';

@Component({
  selector: 'app-confirmar-finalizacao',
  templateUrl: './confirmar-finalizacao.component.html',
  styleUrls: ['./confirmar-finalizacao.component.scss']
})
export class ConfirmarFinalizacaoComponent implements OnInit {

  public message: string = null

  constructor(public ordemService: OrdemService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmarFinalizacaoComponent>) { }

  ngOnInit(): void {
  }

  onNoClick(){
    this.dialog.closeAll()
  }

 async finalizar(){
   await this.ordemService.updateStatusOrdem(this.message, this.ordemService.pagador.pagador.cpf)
    this.dialogRef.close()
  }

}
