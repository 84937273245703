import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { PagadorService } from './pagador.service';
import { de } from 'date-fns/locale';
import { forkJoin, Observable } from 'rxjs';
import { LoginService } from './login.service';
import { timeout } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  cpfPorPagador = ''
  msgTimeout = 'Tempo de execução excedido. Tente novamente.'
  chosenCartorio = {
    userId: '',
    cep: ''
  }

  cartorios = [
    {
      id: '390228c0-eef0-11ea-924e-0af504ceb319',
      cep: '05401450'
    },
    {
      id: '81147fbf-14a0-11eb-8252-0a96c6f995dd',
      cep: '01220010'
    },
    {
      id: '94dcbdf5-1977-11eb-8252-0a96c6f995dd',
      cep: '05401450'
    }
  ]

  public httpOptions = {
    headers: new HttpHeaders(
      {
        'Authorization': this.tokenService.token?.access_token,
        'cartorio': this.tokenService.codigoCartorio,
        'user': this.tokenService.usuario?.email,
        'identificacao': this.cpfPorPagador
      }
    )
  };

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    public loginService: LoginService,
    private loadingService: LoadingService,
    private _snackBar: MatSnackBar) {
  }

  ativarSnackBar(mensagem) {
    this._snackBar.open(mensagem, 'Ok', {
      duration: 4000,
      panelClass: ['error-snackbar']
    });
  }
  setHeader(token) {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token,
          'cartorio': this.tokenService.codigoCartorio,
          'user': this.tokenService.usuario?.email,
          'identificacao': this.cpfPorPagador
        }
      ),
    }
  }

  //APIS DE SOLICITAÇÃO DE CERTIDÃO


  async getApi<T>(params) {
    await this.tokenService.validarToken()
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.tokenService.token?.access_token,
        'cartorio': this.tokenService.codigoCartorio,
        'user': this.tokenService.usuario?.email,
        'identificacao': this.cpfPorPagador
      }),
    };
    return this.http.get<T>(environment.url + params, httpOptions).toPromise()
  }

  getApiCep<T>(params): Observable<T> {
    return this.http.get<T>(environment.url + params, this.httpOptions)

  }
  getCepApi<T>(params): Observable<T> {
    return this.http.get<T>(params)
  }

  postFormApi<T>(params, body): Observable<T> {
    return this.http.post<T>(environment.url + params, body, this.httpOptions)
  }

  async postEstorno(chave, body) {
    await this.tokenService.validarToken()
    return this.http.post<any>('https://gateway.inovacartorios.com.br/' + chave, body).toPromise()
  }
  async postCancelarPedido(chave, body) {
    await this.tokenService.validarToken()
    return this.http.post<any>('https://gateway.inovacartorios.com.br/' + chave, body).toPromise()
  }

  postApiText(params, body, headers?) {
    return this.http.post<string>(environment.url + params, body, headers)
  }

  postFork(params, data) {
    const chamada = data.map(res => {
      return this.http.post(environment.url + params, res, this.httpOptions)
    })
    return forkJoin(chamada)
  }
  // -----------------------------------------------------------------

  /* API SOLICITAÇÃO DE ESCRITURA */

  getApiEscrituras<T>(params: string): Observable<T> {
    return this.http.get<T>(environment.urlEscritura + params)
  }

  postApiEscrituras(params: string, body: any) {
    return this.http.post(environment.urlEscritura + params, body).toPromise()
  }

  putApiEscrituras(url: string, body: any) {
    return this.http.put<string>(environment.urlEscritura + url, body)
  }

  /* ------------------------------------------------------------------ */



  /* API CHAMADAS NOTIFICAÇÃO */

  async putApiNotification(url, body) {
    await this.tokenService.validarToken()
    return this.http.put<string>(environment.urlNotifications + url, body).toPromise()
  }

  async getApiNotification<T>(params) {
    await this.tokenService.validarToken()

    return this.http.get<T>(environment.urlNotifications + params).toPromise()
  }

  /* ----------------------------- */

  setHeaderBusca(token, cpf) {
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token,
          'cartorio': this.tokenService.codigoCartorio,
          'user': this.tokenService.usuario?.email,
          'identificacao': this.cpfPorPagador
        }
      ),
    }
  }

  async postApi(url, body) {
    await this.tokenService.validarToken()
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.tokenService.token?.access_token,
        'cartorio': this.tokenService.codigoCartorio,
        'user': this.tokenService.usuario?.email,
        'identificacao': this.cpfPorPagador
      }),
    };
    return this.http.post<any>(environment.url + url, body, httpOptions).toPromise()
  }

  async putApi(url, body) {
    await this.tokenService.validarToken()
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': this.tokenService.token?.access_token,
        'cartorio': this.tokenService.codigoCartorio,
        'user': this.tokenService.usuario?.email,
        'identificacao': this.cpfPorPagador
      }),
    };
    return this.http.put<string>(environment.url + url, body, httpOptions).toPromise()
  }
}



