import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { TokenService } from 'src/app/services/token.service';
import { InfoService } from '../../services/info.service';

@Component({
  selector: 'inova-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  name: string = '';

  constructor(public infoService: InfoService,
    public notificationService: NotificationService,
    public tokenService: TokenService) { }

  ngOnInit(): void {
    this.notificationService.showNotification = false
    let logged_user = localStorage.getItem('logged-user');
    if (logged_user) {
      let parsed = JSON.parse(logged_user);
      this.name = parsed.nome ? parsed.nome : 'Usuário não Identificado';
    }
  }

  openCloseNotifications() {
    setTimeout(() => {
      this.notificationService.showNotification = !this.notificationService.showNotification
    }, 1);
  }

  sair() {
    localStorage.clear()
  }

}
