import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdensPagamentoComponent } from './ordens-pagamento/ordens-pagamento.component'
import { OrdemDetalheComponent } from './ordem-detalhe/ordem-detalhe.component'
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './guards/auth-guard.service'


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'ordens-pagamento', component: OrdensPagamentoComponent, canActivate: [AuthGuardService] },
  { path: 'ordem-detalhe/:codigo', component: OrdemDetalheComponent, canActivate: [AuthGuardService] },
  { path: 'escrituras', canActivate: [AuthGuardService], loadChildren: () => import('./pages/escrituras/escrituras.module').then(m => m.EscriturasModule) },
  { path: 'solicitacoes', canActivate: [AuthGuardService], loadChildren: () => import('./pages/solicitacoes/solicitacoes.module').then(m => m.SolicitacoesModule) },
  { path: 'configuracoes', canActivate: [AuthGuardService], loadChildren: () => import('./pages/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule) },
  { path: 'dashboard', canActivate: [AuthGuardService], loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'relatorios', canActivate: [AuthGuardService], loadChildren: () => import('./pages/relatorios/relatorios.module').then(m => m.RelatoriosModule) },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
